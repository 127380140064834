import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-email-confirmed",
  templateUrl: "./email-confirmed.component.html",
  styleUrls: ["./email-confirmed.component.scss"],
})
export class EmailConfirmedComponent implements OnInit {
  token: string;
  message: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.token = params["token"];
    });
  }

  ngOnInit(): void {
    this.verifyToken();
  }

  verifyToken() {
    this.authService
      .verifyEmailToken(this.token)
      .pipe(
        map((data) => {
          if (data) {
            this.message = "Email successfully confirmed";
          }
          this.router.navigateByUrl("/");
        }),
        catchError((err) => {
          if (err.status === 404) {
            this.message = "Invalid link";
          } else if (err.status === 400) {
            this.message = "Link expired";
          }
          return of(err);
        })
      )
      .subscribe();
  }
}
