import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { DateRangeModel } from "../models/view-models/dateRange.model";

@Injectable({
  providedIn: "root",
})
export class DocumentService {
  Endpoints = environment;

  constructor(private httpClient: HttpClient) { }

  public getArchivedDocument(): Observable<any> {
    const requestUrl = this.Endpoints.serverEndpoint + `document/archived`;
    return this.httpClient.get<any>(requestUrl);
  }

  public getADocument(id): Observable<any> {
    const requestUrl = this.Endpoints.serverEndpoint + `document/${id}`;
    return this.httpClient.get<any>(requestUrl);
  }

  public getAdminDashboardCount(divisionId): Observable<any> {
    const requestUrl =
      this.Endpoints.serverEndpoint + `admin/dashboard-count/${divisionId}`;
    return this.httpClient.get<any>(requestUrl);
  }

  public getDashboardCount(): Observable<any> {
    const requestUrl =
      this.Endpoints.serverEndpoint + `document/dashboard-count`;
    return this.httpClient.get<any>(requestUrl);
  }

  public getCourtDashboardCount(courtId): Observable<any> {
    const requestUrl =
      this.Endpoints.serverEndpoint + `admin/dashboard-count/court/${courtId}`;
    return this.httpClient.get<any>(requestUrl);
  }

  public getServiceProof(documentId): Observable<any> {
    const requestUrl =
      this.Endpoints.serverEndpoint + `document/Serviceproof/${documentId}`;
    return this.httpClient.get<any>(requestUrl);
  }

  public getDocumentList(stageId = 0, page = 1): Observable<any> {
    const requestUrl =
      this.Endpoints.serverEndpoint +
      `document?stageId=${stageId}&page=${page}`;
    return this.httpClient.get<any>(requestUrl, { observe: "response" });
  }

  public getAdminDocumentList(
    divisionId,
    stageId = 0,
    page = 1
  ): Observable<any> {
    const requestUrl =
      this.Endpoints.serverEndpoint +
      `admin/Documents/division/${divisionId}?stageId=${stageId}&page=${page}`;
    return this.httpClient.get<any>(requestUrl, { observe: "response" });
  }

  public getCourtDocumentList(courtId, stageId = 0, page = 1): Observable<any> {
    const requestUrl =
      this.Endpoints.serverEndpoint +
      `admin/Documents/court/${courtId}?stageId=${stageId}&page=${page}`;
    return this.httpClient.get<any>(requestUrl, { observe: "response" });
  }

  public getNewDocuments(
    courtId,
    page = 1,
    dateRange: DateRangeModel
  ): Observable<any> {
    let requestUrl = `${this.Endpoints.serverEndpoint}admin/new/Documents/court/${courtId}?page=${page}`;
    if (dateRange) {
      requestUrl += `&StartDateVal=${dateRange.StartDateVal}&EndDateVal=${dateRange.EndDateVal}`;
    }
    return this.httpClient.get<any>(requestUrl, { observe: "response" });
  }

  public getExistingDocuments(
    courtId,
    page = 1,
    dateRange: DateRangeModel
  ): Observable<any> {
    let requestUrl = `${this.Endpoints.serverEndpoint}admin/new/Documents/court/${courtId}?page=${page}`;
    if (dateRange) {
      requestUrl += `&StartDateVal=${dateRange.StartDateVal}&EndDateVal=${dateRange.EndDateVal}`;
    }
    return this.httpClient.get<any>(requestUrl, { observe: "response" });
  }

  public addDocument(payload): Observable<any> {
    const requestUrl = this.Endpoints.serverEndpoint + `document`;
    return this.httpClient.post<any>(requestUrl, payload);
  }

  public updateDocument(payload): Observable<any> {
    const requestUrl = this.Endpoints.serverEndpoint + `document/update`;
    return this.httpClient.put<any>(requestUrl, payload);
  }

  public updateDocumentFile(payload): Observable<any> {
    const requestUrl =
      this.Endpoints.serverEndpoint + `document/UpdateDocumentFile`;
    return this.httpClient.put<any>(requestUrl, payload);
  }

  public downloadDocument(name): Observable<any> {
    const payload = {
      fileUniquename: name
    }
    const requestUrl =
      this.Endpoints.serverEndpoint +
      `document/Downloadfile`;
    return this.httpClient.post<any>(requestUrl, payload);
  }

  public onCourtSubmit(documentId): Observable<any> {
    const requestUrl =
      this.Endpoints.serverEndpoint + `document/Submission/${documentId}`;
    return this.httpClient.get<any>(requestUrl);
  }

  public sendReceipt(receiptUri): Observable<any> {
    const requestUrl = this.Endpoints.serverEndpoint + `document/SendReceipt`;
    return this.httpClient.post<any>(requestUrl, receiptUri);
  }
}
