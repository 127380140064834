import { Injectable } from "@angular/core";
import { CanLoad, Router, CanActivate } from "@angular/router";
import { CurrentUserService } from "../factories/current-user.service";

@Injectable({
  providedIn: "root",
})
export class UserGuardService implements CanLoad, CanActivate {
  constructor(
    private currentUserService: CurrentUserService,
    private router: Router
  ) {}
  canLoad() {
    return this.checkClearance();
  }

  canActivate() {
    return this.checkClearance();
  }

  private checkClearance() {
    const currentUser = this.currentUserService.getTempUserProfile();
    const currentUserRole = currentUser ? currentUser.role : false;
    if (
      currentUser &&
      !(currentUserRole === "SuperAdmin" || currentUserRole === "Administrator")
    ) {
      return true;
    } else if (
      currentUser &&
      (currentUserRole === "SuperAdmin" || currentUserRole === "Administrator")
    ) {
      this.router.navigate(["/admin"]);
      return false;
    } else {
      this.router.navigate(["home"]);
      return false;
    }
  }
}
