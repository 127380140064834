import { Injectable } from "@angular/core";
import { LocalStorageService } from "ngx-webstorage";
import { Observable, of, throwError } from "rxjs";
import { PermissionViewModel } from "src/app/models/view-models/permission";
import { isNullOrUndefined } from "util";
import { TokenParams } from "../../models/generic-models/token-params";
import {
  TemmpUserProfile,
  TempUserDetails,
  UserDetail,
  TempAdminProfile,
  TempAdminDetails,
} from "../../models/view-models/user-detail";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CurrentUserService {
  currentUser = new UserDetail();
  Endpoints = environment;
  constructor(
    private localStorage: LocalStorageService,
    private httpClient: HttpClient
  ) {}

  getTempUserProfile() {
    const currentUser = JSON.parse(this.localStorage.retrieve("user_profile"));
    return currentUser;
  }

  getEmail() {
    const email = this.localStorage.retrieve("user_email");
    return email;
  }

  getuserProfile(): Observable<UserDetail> {
    const value = this.localStorage.retrieve("user_profile");
    if (value !== "" || " ") {
      let userData = new UserDetail();
      userData = JSON.parse(value);
      const email = this.localStorage.retrieve("user_email");
      const role = this.localStorage.retrieve("user_role");
      if (!isNullOrUndefined(role) && !isNullOrUndefined(email)) {
        userData.email = email;

        userData.role = role;
      } else {
        return throwError("user email ans role is not avalilable");
      }
      return of(userData);
    } else {
      return throwError("an error occured");
    }
  }

  // isAdmin

  getToken() {
    const tk = this.localStorage.retrieve("token");
    if (!isNullOrUndefined(tk)) {
      return this.localStorage.retrieve("token");
    }
    // return this.cookieService.get('token');
  }

  addToken(token: TokenParams, permissions: PermissionViewModel[]) {
    // we delete the existing first
    this.localStorage.clear("token");
    this.localStorage.clear("refresh_token");
    this.localStorage.clear("expires_in");
    this.localStorage.clear("perm_acc");

    // then add
    if (!isNullOrUndefined(token)) {
      this.localStorage.store("token", token.auth_token);
      this.localStorage.store("refresh_token", token.refresh_token);
      this.localStorage.store("expires_in", token.expires_in);
      this.localStorage.store("perm_acc", JSON.stringify(permissions));
    }
  }

  addTemporaryToken(token: TempUserDetails) {
    if (!isNullOrUndefined(token)) {
      this.localStorage.store("token", token.token);
      this.localStorage.store("refresh_token", token.refreshToken);
      const userprof: TemmpUserProfile = {
        emailAddress: token.emailAddress,
        firstName: token.firstName,
        userId: token.userId,
        role: token.role,
      };
      this.addAdminProfile(userprof);
    }
  }

  addTemporaryAdminToken(token: TempAdminDetails) {
    if (!isNullOrUndefined(token)) {
      this.localStorage.store("token", token.token);
      this.localStorage.store("refresh_token", token.refreshToken);
      const adminProf: TempAdminProfile = {
        emailAddress: token.emailAddress,
        firstName: token.firstName,
        lastName: token.lastName,
        userId: token.userId,
        role: token.role,
        divisionId: token.divisionId,
      };
      this.addUserProfile(adminProf);
    }
  }

  addRedirectUrl(url: string) {
    this.localStorage.store("redirect_url", url);
  }

  getRedirectUrl(): string {
    if (!isNullOrUndefined(this.localStorage.retrieve("redirect_url"))) {
      return this.localStorage.retrieve("redirect_url");
    } else {
      return "";
    }
  }

  removeRedirectUrl() {
    this.localStorage.clear("redirect_url");
  }

  isLoggedIn() {
    const token = this.localStorage.retrieve("token");
    this.currentUser = JSON.parse(this.localStorage.retrieve("user_profile"));
    if (
      (token !== null || token !== undefined) &&
      (this.currentUser !== null || this.currentUser !== undefined)
    ) {
      if (token && token.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isRegistrar(): Observable<any> {
    const userid = this.getUserId();
    const requestUrl =
      this.Endpoints.serverEndpoint + `settings/usertype/${userid}`;
    return this.httpClient.get<any>(requestUrl);
  }

  isLicensed() {
    // const token = this.cookieService.get('token');
    const token = this.localStorage.retrieve("token");
    this.currentUser = JSON.parse(this.localStorage.retrieve("user_profile"));
    if (!isNullOrUndefined(token) && !isNullOrUndefined(this.currentUser)) {
      if (this.currentUser.isLicensed) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  addUserProfile(userProfile) {
    this.localStorage.store("user_profile", JSON.stringify(userProfile));
    // emitted to
  }

  addAdminProfile(adminProf) {
    this.localStorage.store("user_profile", JSON.stringify(adminProf));
    // emitted to
  }

  addEmail(email: string) {
    this.localStorage.store("user_email", email);
    // emitted to
  }

  addRole(role: string) {
    this.localStorage.store("user_role", role);
  }

  logout() {
    this.localStorage.clear("user_profile");
    this.localStorage.clear("token");
    this.localStorage.clear("user_permits");
    this.localStorage.clear("user_email");
    this.localStorage.clear("user_role");
  }

  clearPermission() {
    this.localStorage.clear("user_permits");
  }

  getUserId(): number {
    this.currentUser = JSON.parse(this.localStorage.retrieve("user_profile"));
    return this.currentUser.userId;
  }

  getAllEmailTokenStrings(): string[] {
    let emailTokens: string[] = [];
    const ls_tkn = this.localStorage.retrieve("user_emailAccounts");
    if (!isNullOrUndefined(ls_tkn) && ls_tkn !== "") {
      emailTokens = JSON.parse(ls_tkn);
    }
    return emailTokens;
  }
}
