<h2>REGISTRATION SUCCESSFUL</h2>
<h3>JUST ONE MORE STEP...</h3>
<p>
  Please verify your email address so you can sign in if you ever forget your password. We've sent a confirmation email to:
  <br>
  <br>
  <a>{{email}}</a>
</p>
<br>
<br>
<p>
  If you still don't see it after some minutes, you can check your span folder or click <a (click)="resendConfirmationEmail()" href="javascript:;">here</a> to resend the confirmation email
</p>
<span *ngIf="showSent" class="alert alert-success">
  Confirmation email has been successfully resent.
</span>
