import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Token } from "@angular/compiler/src/ml_parser/lexer";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { UserLoginDetail } from "../../models/input-models/login";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  Endpoints = environment;

  constructor(private httpClient: HttpClient) {}

  public signIn(user: UserLoginDetail): Observable<any> {
    // const requestUrl = baseApi + 'Account/Login';
    const requestUrl =
      this.Endpoints.serverEndpoint + this.Endpoints.loginEndPoint;
    // const data = 'email=' + user.email + '&password=' + user.password;
    return this.httpClient.post<any>(requestUrl, user, { observe: "response" });
  }

  public signOut(email: string): Observable<any> {
    // const requestUrl = baseApi + 'Account/Login';
    const requestUrl =
      this.Endpoints.serverEndpoint + `account/${email}/logout`;
    // const data = 'email=' + user.email + '&password=' + user.password;
    return this.httpClient.get<any>(requestUrl);
  }

  public validateEmail(e) {
    // yet to complete endpoint url
    const requestUrl =
      this.Endpoints.serverEndpoint + this.Endpoints.validateEmail + e;
    return this.httpClient.get<any>(requestUrl, e);
  }

  register(user: any) {
    // yet to complete endpoint url
    const requestUrl =
      this.Endpoints.serverEndpoint + this.Endpoints.registerEndPoint;
    const headerConfig = {
      "Without-Auth": "true",
    };
    const headers = new HttpHeaders(headerConfig);
    return this.httpClient
      .post(requestUrl, user, { headers })
      .pipe(catchError(this.handleError));
  }

  Registrar(user: any) {
    // yet to complete endpoint url
    const requestUrl = this.Endpoints.serverEndpoint + "account/register";
    return this.httpClient
      .post(requestUrl, user)
      .pipe(catchError(this.handleError));
  }

  public confirmEmail(token: string) {
    const requestUrl =
      this.Endpoints.serverEndpoint +
      this.Endpoints.confirmEmailEndPoint +
      "?token=" +
      token;
    return this.httpClient.get<Observable<any>>(requestUrl);
  }

  public resendConfirmation(email: string) {
    const requestUrl =
      this.Endpoints.serverEndpoint + `firm/ResendComfirmation`;
    return this.httpClient.post<Observable<any>>(requestUrl, { email });
  }

  public createFirm(payload: any) {
    const requestUrl = this.Endpoints.serverEndpoint + `account`;
    return this.httpClient.post<Observable<any>>(requestUrl, payload);
  }

  public createAdminProfile(payload: any) {
    const requestUrl = this.Endpoints.serverEndpoint + `account/register`;
    return this.httpClient.post<Observable<any>>(requestUrl, payload);
  }

  // public inviteeRegister(user: RegisterInvitedUser) {
  //
  //   // yet to complete endpoint url
  //   const requestUrl = this.config.getConfig().serverEndpoint + this.Endpoints.addfirmUser;
  //   return this.httpClient.post(requestUrl, user);
  // }

  public forgotPassword(email: string): Observable<any> {
    const requestUrl =
      this.Endpoints.serverEndpoint + `account/forgot-password`;
    return this.httpClient.post<Token>(requestUrl, { email });
  }

  public resetPassword(
    password: string,
    confirmPassword: string,
    token: string,
    email: string
  ): Observable<boolean> {
    const requestUrl = this.Endpoints.serverEndpoint + `account/reset-password`;
    return this.httpClient.post<boolean>(requestUrl, {
      email,
      password,
      token,
      confirmPassword,
    });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(error.error);
  }
}
