import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxWebstorageModule } from "ngx-webstorage";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthenticationModule } from "./authentication/authentication.module";
import { CoreModule } from "./core/core.module";
import { HomeModule } from "./home/home.module";
import { AuthInterceptor } from "./interceptors/auth-interceptor";
import { AuthService } from "./services/auth.service";
import { PageLoaderComponent } from "./shared/page-loader/page-loader.component";
import { TermsComponent } from "./shared/terms/terms.component";

@NgModule({
  declarations: [AppComponent, PageLoaderComponent, TermsComponent],
  imports: [
    BrowserModule,
    NgxWebstorageModule.forRoot(),
    CoreModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthenticationModule,
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
