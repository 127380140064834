<div class="modal fade" id="terms___" tabindex="-1" role="dialog" aria-labelledby="updatePhonenoModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="updatePhonenoModalLabel">LAWPAVILION’S PRIVACY POLICY </h5>
      </div>
      <div class="modal-body">
        <article>
          <p>
            Your data privacy is important to us. This means that we process data about identified or identifiable
            individuals, which is called personal data, with due care and in accordance with applicable data protection
            law. Carefully read the provisions below:
            This eRegistry is developed by LawPavilion in conjunction with the judiciary and this Terms of Use
            (<b>‘Policy’</b>) explains what information we collect when you use this eRegistry. It also has information
            about how we store, use, transfer or otherwise deal with such collected information. Our aim is not just to
            comply with Privacy Law, it is to earn your trust.
          </p>
          <section>
            <h4>The Data We Collect </h4>
            <ul>
              <li>Personal identifiable information (name, email address and phone number)</li>
              <li>Demographic Information (e.g. IP address, zip code)</li>
              <li>Technical Information (Device identifiers, web browser information, page view statistics, Browsing
                history)</li>
              <li>Payment information, including credit card number, contact information, and billing information.</li>
              <li>Cookies and other tracking technologies (e.g. web beacons, pixel tags, SDKs, etc.)</li>
              <li>Log data (Usage information e.g. access times, hardware and software information)</li>
            </ul>
          </section>

          <section>
            <h4>How We Collect the Data</h4>
            <label>You generally provide us with the data we collect when:</label>
            <ul>
              <li>You provide us with certain personally identifiable information that can be used to contact or
                identify you, or the other party such as your name, email and phone number ("Personal Information") for
                log in or sign in. </li>
              <li>You provide us with certain personally identifiable information of the other party such as their name,
                email and phone number ("Personal Information"). </li>
            </ul>
            <p>
              In addition to this, we may also receive your data indirectly through your log data which may include
              information such as your computer's Internet Protocol ("IP") address, geographical location, device type,
              browser type, browser version, how you interact with our eRegistry, the time and date of your visit, the
              time spent on those pages and other statistics. Also, we may use third party services such as Google
              Analytics that collect, monitor and analyze the information collected.
            </p>
          </section>

          <section>
            <h4>How We Use Your Data </h4>
            <p>Data collected are processed ultimately for the use of the Court (data collector).</p>
            <label>In addition, we process this Personal Data based on the following legitimate business interests,
              to:</label>
            <ul>
              <li>make this eRegistry work as best as possible </li>
              <li>improve our services and products </li>
              <li>ensure network security</li>
              <li>administrative matters</li>
              <li>fight spam and other forms of abuse</li>
              <li>generate aggregate, non-identifying information about how people use the eRegistry;</li>
              <li>send you promotional emails containing the information we think you will find interesting;</li>
              <li>providing third parties with statistical information about our users (but those third parties will not
                be able to identify any individual user from that information); </li>
              <li>dealing with inquiries and complaints made by or about you relating to the eRegistry; </li>
              <li>verifying compliance with the terms and conditions governing the use of the eRegistry (including
                monitoring private messages sent through our eRegistry private messaging service)</li>
            </ul>
          </section>

          <section>
            <p>
              For better clarity, below is a tabular representation of data collected and the legal basis for such
              collection: </p>
            <div class="grid-2">
              <section>
                <h4>Personal Information</h4>
                <p>
                  Contact information, including name, address, e-mail address, and telephone number.
                </p>
                <p>Purchaser information, including credit card number, contact information, and billing information.
                </p>
                <p>Demographic information, including age, career focus and discipline, preferences, gender, educational
                  degrees, ethnic background, interests, and household income range.</p>
                <p>Technical information, including your IP address, browser type, domain names, access times, and
                  referring eRegistry addresses.</p>
              </section>
              <section>
                <h4>Legal Basis for Processing</h4>
                <p>Consent to provide you with the content you request, as necessary for the performance of eRegistry
                  functions.</p>
                <p>Necessary to effect online payment within the eRegistry</p>
                <p>Legitimate purpose of providing tailored content to you.</p>
                <p>Legitimate purpose of authenticating subscribers and optimizing eRegistry performance.</p>
              </section>
            </div>
          </section>

          <section>
            <h4>Storing and Securing the Data</h4>
            <label>LawPavilion and the Judiciary are committed to securing your data and keeping it confidential. We
              will ensure that:</label>
            <ul>
              <li>your data is secured against all foreseeable hazards and breaches such as theft, cyberattack, viral
                attack, dissemination, manipulations of any kind, </li>
              <li>Your data is secured against damage by rain, fire or exposure to other natural elements. </li>
              <li>All electronic financial transactions entered into through our eRegistry will be protected by
                encryption technology.</li>
            </ul>
            <p>
              However, while we strive to use commercially acceptable means to protect your Personal Information; you
              acknowledge, by agreeing to this policy, that no method of transmission over the Internet or method of
              electronic storage is 100% secure, and we cannot guarantee the absolute security of data sent over the
              internet.
            </p>
            <p>
              You are exclusively responsible for keeping the password you use for accessing the eRegistry confidential;
              we will not ask you for your password (except when you log in to our eRegistry).
            </p>
          </section>

          <section>
            <h4>Retention of your Personal Data</h4>
            <p>Personal information that we process for any purpose or purposes shall not be kept for longer than is
              necessary for that purpose or those purposes.</p>
            <p>We retain indefinitely certain anonymized information we gather about visitors or subscribers for the
              legitimate purpose of performing eRegistry analytics and providing use of our eRegistry to all visitors in
              an efficient, practical, and relevant way. </p>
            <p>If there is no legal basis or other requirement for keeping your data AND after there has been no
              activity from you for twenty-six (26) months, your data that is no longer required will be purged as may
              be agreed with the joint data controller (the Judiciary).</p>
            <label>Notwithstanding the other provisions of this Section, we will retain electronic documents containing
              personal data:</label>
            <ul>
              <li>to the extent that we are required to do so by law;</li>
              <li>if we believe that the documents may be relevant to any ongoing or prospective legal proceedings; and
              </li>
              <li>in order to establish, exercise, or defend our legal rights (including providing information to others
                for the purposes of fraud prevention and reducing credit risk).</li>
            </ul>
          </section>

          <section>
            <h4>The transfer of your Personal Data outside Nigeria</h4>
            <p>Personal Data captured on this eRegistry may be transferred to, and stored or otherwise processed at, a
              destination outside Nigeria, including destinations that are not subject to Data Privacy Provisions
              equivalent to those in Nigeria in which you provided your Personal Data.  We transfer your data to such
              other jurisdictions with your consent or where necessary to:</p>
            <ul>
              <li>provide you with the services you have requested; or</li>
              <li>for the conclusion or performance of a contract concluded in your interest between the us and a third
                party; or</li>
              <li>for reasons of public interest; </li>
              <li>for the establishment, exercise or defence of legal claims; </li>
              <li>in order to protect the vital interests of the Data Subject or of other persons, where the data
                subject is physically or legally incapable of giving consent; </li>
            </ul>
            <p>Even in all of such instances, we will take all steps reasonably necessary to ensure that your Personal
              Data is processed safely and in accordance with this Privacy Policy.</p>
          </section>

          <section>
            <h4>Your Data Protection Rights </h4>
            <p>You have certain rights with respect to your Personal Data, including those set forth below.  Please note
              that in some circumstances, we may not be able to fully comply with your request, such as if it is
              frivolous or extremely impractical, if it jeopardizes the rights of others, or if it is not required by
              law, but in those circumstances, we will still respond to notify you of such a decision.  In some cases,
              we may also need to you to provide us with additional information, which may include Personal Data, if
              necessary to verify your identity and the nature of your request.</p>

            <p>
              <b>Right to Access:</b>
              You can request more information about the Personal Data we hold about you and request a copy of such
              Personal Data. We may charge you a small fee for this service.
            </p>
            <p>
              <b>Right to rectification:</b>
              If you believe that any Personal Data we are holding about you is inaccurate or incomplete, you can
              request that we correct or supplement such Personal Data.
            </p>

            <p>
              <b>Right to erasure:</b>
              Under certain conditions, you can request that we erase some or all of your Personal Data from our
              systems. Although we may not immediately be able to erase all residual copies from our servers and backup
              systems after the active data have been erased. Such copies shall be erased as soon as reasonably
              possible.
            </p>

            <p>
              <b>Right to Portability: </b>
              You can ask for a copy of your Personal Data in a machine-readable format.  You can also request, under
              certain conditions, that we transmit the data to another controller or directly to you, where technically
              feasible. This right shall however not apply to processing necessary for the performance of a task carried
              out in the public interest or in the exercise of official authority vested in us.
            </p>

            <p>
              <b>Right to restrict Processing:</b>
              You can ask us to restrict further processing of your Personal Data, under certain conditions. Restriction
              of processing may lead to fewer possibilities to use our eRegistry. Where processing has been restricted,
              your personal data shall, with the exception of storage, only be processed with your consent or for the
              establishment, exercise or defence of legal claims or for the protection of the rights of another natural
              or legal person or for reasons of important public interest in Nigeria.
            </p>

            <p>
              <b>Right to object: </b>
              You can contact us to let us know that you object to the further use or disclosure of your Personal Data
              for certain purposes, such as for direct marketing purposes. We shall then no longer process User’s
              personal data unless for the provision of our services or if we demonstrate other compelling legitimate
              grounds for our processing that override User’s interests, rights and freedoms or for legal claims.
            </p>

            <p>
              <b>Withdrawal of Consent: </b>
              If you have provided consent for the processing of your data, you have the right (in certain
              circumstances) to withdraw that consent at any time, which will not affect the lawfulness of the
              processing before your consent was withdrawn. Please note, however, that if you exercise this right, you
              may have to then provide express consent on a case-by-case basis for the use or disclosure of certain of
              your Personal Data, if such use or disclosure is necessary to enable you to utilize some or all of our
              services.
            </p>

            <p>
              <b>Right to lodge a complaint: </b>
              If at any time you are convinced beyond reasonable doubt that your data is not duly protected by us, you
              have the right to lodge a complaint with a relevant authority after adequate notice has been given to us
              and we have been given the opportunity to remedy such wrong without success.
            </p>
          </section>

          <section>
            <h4>How to use these rights.</h4>
            <p>To exercise any of the above-mentioned rights, User should primarily use the functions offered by our
              eRegistry. If such functions are however not sufficient for exercising such rights, User shall send us a
              letter or email to the address set out below under Contact, including the following information: name,
              address, phone number, email address and a copy of a valid proof of identity. We may request additional
              information necessary to confirm User’s identity. We may reject requests that are unreasonably repetitive,
              excessive or manifestly unfounded. </p>
          </section>

          <section>
            <h4>Changes to our Policy</h4>
            <p>This Privacy Notice is dated 2022. We may update this Policy at any time if required in order to reflect
              changes in our data processing practices, in personal data protection laws or otherwise. For substantial
              changes to this Privacy Notice, we will use reasonable endeavors to provide notice thereof.</p>
          </section>

        </article>
      </div>
      <div class="modal-footer right">
        <button type="button" class="btn__primary sm" data-dismiss="modal">
          CONTINUE
        </button>
      </div>
    </div>
  </div>
</div>
