import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { GeneralService } from "../../services/general.service";
import { AuthService } from "../../services/services/auth.service";
import { PasswordValidation } from "../../shared/helpers/password-validator";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-admin-registration",
  templateUrl: "./admin-registration.component.html",
  styleUrls: ["./admin-registration.component.scss"],
})
export class AdminRegistrationComponent implements OnInit {
  token: string;
  inviteeEmail: string;
  role: string;
  PhoneNumber: any;

  registerForm: FormGroup;
  btnSpinner = false;

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activatedRoute.queryParamMap.subscribe((data) => {
      if (data) {
        this.token = data.get("token").replace(/\s/g, "+");
        this.inviteeEmail = data.get("InviteeEmail");
        this.role = data.get("Role");
        this.PhoneNumber = data.get("PhoneNumber");
      }
    });
    this.createForm();
  }

  ngOnInit(): void {}

  openSnackBar(message) {
    this.snackBar.open(message, "Close", {
      // duration: 10000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  createForm() {
    this.registerForm = this.fb.group(
      {
        firstname: ["", Validators.required],
        lastname: ["", Validators.required],
        personal: ["", Validators.required],
        official: [
          { value: this.inviteeEmail, disabled: true },
          Validators.required,
        ],
        phoneNumber: [{ value: `+${this.PhoneNumber}`, disabled: true }],
        password: ["", Validators.required],
        otherName: [""],
        confirmPassword: ["", Validators.required],
      },
      {
        validator: PasswordValidation.MatchPassword, // your validation method
      }
    );
  }

  get firstname() {
    return this.registerForm.get("firstname");
  }
  get lastname() {
    return this.registerForm.get("lastname");
  }
  get personal() {
    return this.registerForm.get("personal");
  }
  get official() {
    return this.registerForm.get("official");
  }
  get phoneNumber() {
    return this.registerForm.get("phoneNumber");
  }
  get password() {
    return this.registerForm.get("password");
  }
  get confirmPassword() {
    return this.registerForm.get("confirmPassword");
  }
  get otherName() {
    return this.registerForm.get("otherName");
  }

  registerAdmin() {
    this.btnSpinner = true;

    const payload = {
      personalEmail: this.personal.value,
      password: this.confirmPassword.value,
      firstName: this.firstname.value,
      lastName: this.lastname.value,
      otherName: this.otherName.value,
      token: this.token,
    };

    this.authService.createAdminProfile(payload).subscribe(
      (res) => {
        this.btnSpinner = false;
        this.router.navigate(["/login/admin"]);
      },
      (err) => {
        this.btnSpinner = false;
        this.openSnackBar(err.error);
      }
    );
  }
}
