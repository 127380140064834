import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-forgot-password-modal",
  templateUrl: "./forgot-password-modal.component.html",
  styleUrls: ["./forgot-password-modal.component.scss"],
})
export class ForgotPasswordModalComponent implements OnInit {
  @ViewChild("closeBtn") closeBtn: ElementRef;
  @Output("onSent") onSent = new EventEmitter();
  isLoading: boolean;
  email;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {}

  submitFogotPassword(email) {
    this.isLoading = true;
    this.authService.forgotPassword(email).subscribe(
      (data) => {
        if (data) {
          this.isLoading = false;

          this.onSent.emit(true);
          this.closeBtn.nativeElement.click();
        }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
}
