import { Location } from "@angular/common";
import { Token } from "@angular/compiler/src/ml_parser/lexer";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { UserLoginDetail } from "src/app/models/input-models/login";
import {
  TempUserDetails,
  UserDetail,
  TempAdminDetails,
} from "src/app/models/view-models/user-detail";
import { AuthService } from "src/app/services/auth.service";
import { CurrentUserService } from "src/app/services/factories/current-user.service";
import { isNullOrUndefined } from "util";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-admin-login",
  templateUrl: "./admin-login.component.html",
  styleUrls: ["./admin-login.component.scss"],
})
export class AdminLoginComponent implements OnInit {
  loginForm: FormGroup;
  user: UserDetail;
  responseData: Token;
  token: any;
  loginShowError;
  userProfile: UserDetail;
  userToken$: Observable<any>;
  loginBtnSpinner: boolean;
  isLoginError: boolean;
  emailVal: string;
  errorMessage: any;
  alreadyLoogedIn: boolean;
  status: string;
  showError = false;
  errMessage: string;

  @ViewChild("errorBox") errorBox: ElementRef;
  isLawyer: boolean;
  isRegistrar: boolean;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private currentUserService: CurrentUserService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private location: Location,
    private loginService: AuthService,
    private route: ActivatedRoute
  ) {
    this.emailVal = this.route.snapshot.paramMap.get("email");
    this.status = this.route.snapshot.queryParamMap.get("id");

    this.createForm();
  }

  ngOnInit(): void {
    this.isLoginError = false;
    this.loginBtnSpinner = false;
    this.alreadyLoogedIn = false;
  }

  get email() {
    return this.loginForm.get("email");
  }

  get password() {
    return this.loginForm.get("password");
  }

  forceLogout() {
    this.router.navigate(["/logout", this.email.value]);
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: [
        this.emailVal,
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      password: ["", Validators.required],
    });
  }

  login() {
    this.loginBtnSpinner = true;

    const userLoginDetail: UserLoginDetail = {
      email: this.email.value,
      password: this.password.value,
      loginRoleId: 1,
    };

    this.authService
      .signIn(userLoginDetail)
      .toPromise()
      .then((response) => {
        // tslint:disable-next-line: deprecation
        if (!isNullOrUndefined(response)) {
          const details: TempAdminDetails = {
            emailAddress: response.body.emailAddress,
            firstName: response.body.firstName,
            lastName: response.body.lastName,
            refreshToken: response.body.refreshToken,
            token: response.body.token,
            userId: response.body.userId,
            role: response.body.role,
            divisionId: response.body.divisionId,
          };
          this.currentUserService.addTemporaryAdminToken(details);
          this.router.navigate(["/admin"]);
        }
      })
      .catch((err) => {
        this.loginBtnSpinner = false;
        this.showError = true;
        this.errMessage = err.error;
        this.openSnackBar(err.error);
      });
  }

  goBack() {
    this.location.back();
  }

  LoginEnter() {
    if (this.email.value !== "" && this.password.value !== "") {
      this.login();
    }
  }

  openSnackBar(message) {
    this.snackBar.open(message, "Ok", {
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 3000,
    });
  }

  notifySent(event) {
    if (event) {
      this.openSnackBar(
        "Done!.. We have sent an Email to you! It will inform you of the next instruction"
      );
    }
  }
}
