import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { GeneralService } from "../../services/general.service";
import { AuthService } from "../../services/services/auth.service";
import { PasswordValidation } from "../../shared/helpers/password-validator";

@Component({
  selector: "app-firm-registration",
  templateUrl: "./firm-registration.component.html",
  styleUrls: ["./firm-registration.component.scss"],
})
export class FirmRegistrationComponent implements OnInit {
  firmRegistryForm: FormGroup;
  token: string;
  judiciaryName: string;
  inviteeEmail: string;
  role: string;
  judiciaryId: number;
  divisionId: number;

  loginBtnSpinner = false;
  listOfStates: any[] = [];

  constructor(
    private fb: FormBuilder,
    private genralService: GeneralService,
    private authService: AuthService
  ) {
    this.firmRegistryForm = fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      officialEmail: ["", Validators.required],
      personalEmail: ["", Validators.required],
      country: [""],
      state: ["", Validators.required],
      city: ["", Validators.required],
      address: ["", Validators.required],
      phoneNumber: ["", Validators.required],
      otherName: [""],
      website: [""],
      password: ["", Validators.required],
      confirmPassword: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.getStates();
  }

  getStates() {
    this.genralService.listOfStates().subscribe((stateList) => {
      this.listOfStates = stateList;
    });
  }

  get firstName() {
    return this.firmRegistryForm.get("firstName");
  }
  get lastName() {
    return this.firmRegistryForm.get("lastName");
  }
  get officialEmail() {
    return this.firmRegistryForm.get("officialEmail");
  }
  get personalEmail() {
    return this.firmRegistryForm.get("personalEmail");
  }
  get country() {
    return this.firmRegistryForm.get("country");
  }
  get state() {
    return this.firmRegistryForm.get("state");
  }
  get city() {
    return this.firmRegistryForm.get("city");
  }
  get address() {
    return this.firmRegistryForm.get("address");
  }
  get phoneNumber() {
    return this.firmRegistryForm.get("phoneNumber");
  }
  get otherName() {
    return this.firmRegistryForm.get("otherName");
  }
  get website() {
    return this.firmRegistryForm.get("website");
  }
  get password() {
    return this.firmRegistryForm.get("password");
  }
  get confirmPassword() {
    return this.firmRegistryForm.get("confirmPassword");
  }

  saveFormData() {
    const payload = {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      courts: [],
      officialEmail: this.officialEmail.value,
      personalEmail: this.personalEmail.value,
      phoneNumber: this.phoneNumber.value,
      password: this.password.value,
      stateId: this.state.value,
      otherName: this.otherName.value,
      judiciary: {
        name: `${this.firstName.value} ${this.lastName.value}`,
        stateId: this.state.value,
        email: this.officialEmail.value,
        address: this.address.value,
        website: this.website.value,
      },
    };

    // this.authService.createFirm(payload).subscribe(
    //   res => {
    //
    //   }
    // );
  }
}
