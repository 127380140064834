import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {

  email: string;
  showSent = false;

  constructor(private route: ActivatedRoute, private authService: AuthService
  ) {
    this.email = this.route.snapshot.queryParamMap.get('email');
  }

  ngOnInit(): void {
  }

  resendConfirmationEmail() {
    this.showSent = false;

    this.authService.resendConfirmationEmail(this.email).pipe(map(data => {
      if (data) {
        this.showSent = true;
      }
    })).subscribe();
  }

}
