import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-confirm-account",
  templateUrl: "./confirm-account.component.html",
  styleUrls: ["./confirm-account.component.scss"],
})
export class ConfirmAccountComponent implements OnInit {
  registeredEmail: string;
  isError: boolean;
  resend: boolean;
  btnSpinner: boolean;

  @ViewChild("errorBox") errorBox: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.isError = false;
    this.btnSpinner = false;
    this.resend = false;

    this.registeredEmail = this.route.snapshot.paramMap.get("email");
  }

  openSnackBar(message) {
    this.snackBar.open(message, "Close", {
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 3000,
    });
  }

  resendConfirmation() {
    this.btnSpinner = true;

    this.authService.resendConfirmationEmail(this.registeredEmail).subscribe(
      (res) => {
        this.btnSpinner = false;
      },
      (err) => {
        this.openSnackBar(err.error);
        this.btnSpinner = false;
      }
    );
  }
}
