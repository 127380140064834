<!-- Modal -->
<div
  class="modal"
  id="updateAddress"
  tabindex="-1"
  role="dialog"
  aria-labelledby="updateProfileLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="updateProfileLabel">
          Judiciary Profile
        </h5>
      </div>
      <div class="modal-body">
        <form [formGroup]="firmRegistryForm">
          <div class="u-grid-column-2">
            <div class="form-group">
              <label class="label" for="name_jud1">FIRST NAME</label>
              <input
                type="text"
                class="form-control"
                id="name_jud1"
                placeholder="Enter first name"
                formControlName="firstName"
              />
            </div>
            <div class="form-group">
              <label class="label" for="name_jud2">LAST NAME</label>
              <input
                type="text"
                class="form-control"
                id="name_jud2"
                placeholder="Enter last name"
                formControlName="lastName"
              />
            </div>
          </div>

          <div class="u-grid-column-2">
            <div class="form-group">
              <label class="label" for="email__1">OFFICIAL EMAIL</label>
              <input
                type="email"
                class="form-control"
                id="email__1"
                placeholder="Enter email address"
                formControlName="officialEmail"
              />
            </div>
            <div class="form-group">
              <label class="label" for="email__2">PERSONAL EMAIL</label>
              <input
                type="email"
                class="form-control"
                id="email__2"
                placeholder="Enter email address"
                formControlName="personalEmail"
              />
            </div>
          </div>

          <div class="u-grid-column-2">
            <div class="form-group">
              <label class="label" for="country__">COUNTRY</label>
              <select
                name=""
                id="country__"
                class="form-control"
                formControlName="country"
              >
                <option selected value="">Nigeria</option>
              </select>
            </div>
            <div class="form-group">
              <label class="label" for="state__">STATE</label>
              <select
                name=""
                id="state__"
                class="form-control"
                formControlName="state"
              >
                <option selected disabled value="">Select a state</option>
                <option *ngFor="let state of listOfStates" [value]="state.id">{{
                  state.name
                }}</option>
              </select>
            </div>
          </div>

          <div class="u-grid-column-2">
            <div class="form-group">
              <label class="label" for="city_">CITY</label>
              <input
                type="text"
                class="form-control"
                id="city_"
                placeholder="Ikeja"
                formControlName="city"
              />
            </div>
            <div class="form-group">
              <label class="label" for="street__">ADDRESS</label>
              <input
                type="text"
                class="form-control"
                id="street__"
                placeholder="xyz, street name"
                formControlName="address"
              />
            </div>
          </div>

          <div class="u-grid-column-2">
            <div class="form-group">
              <label class="label" for="city_">PHONE-NUMBER</label>
              <input
                type="tel"
                class="form-control"
                id="city_"
                formControlName="phoneNumber"
              />
            </div>
            <div class="form-group">
              <label class="label" for="street__">OTHER NAME</label>
              <input
                type="text"
                class="form-control"
                id="street__"
                placeholder="other name"
                formControlName="otherName"
              />
            </div>
          </div>

          <div class="form-group">
            <label class="label" for="website_">WEBSITE</label>
            <input
              type="text"
              class="form-control"
              id="website_"
              formControlName="website"
            />
          </div>

          <div class="u-grid-column-2">
            <div class="form-group">
              <label class="label" for="city_">PASSWORD</label>
              <input
                type="tel"
                class="form-control"
                id="city_"
                formControlName="password"
              />
            </div>
            <div class="form-group">
              <label class="label" for="street__">CONFIRM PASSWORD</label>
              <input
                type="text"
                class="form-control"
                id="street__"
                placeholder="other name"
                formControlName="confirmPassword"
              />
            </div>
          </div>
        </form>

        <!-- ********** -->
      </div>
      <div class="modal-footer center">
        <button type="button" class="btn__primary sm transparent__">
          <div class="loader_item">
            <div class="cm_spinner"></div>
          </div>
          <span>CANCEL</span>
        </button>

        <button
          (click)="saveFormData()"
          [disabled]="firmRegistryForm.invalid || loginBtnSpinner"
          [ngClass]="{ loading: loginBtnSpinner }"
          class="btn__primary has_loader"
        >
          <div class="loader_item">
            <div class="cm_spinner"></div>
          </div>
          SAVE CHANGES
        </button>
      </div>
    </div>
  </div>
</div>
