import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { PasswordValidation } from "../../shared/helpers/password-validator";
import { MatSnackBar } from "@angular/material/snack-bar";
@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.scss"],
})
export class RegistrationComponent implements OnInit {
  lawyerForm: FormGroup;
  invalidEmail: boolean;
  error: string;
  allFieldNotField = false;
  // while page is loading first, before interaction, dont show notification
  onLoadHide: boolean;
  showError: boolean;
  btnSpinner: boolean;
  registrarForm: FormGroup;

  token: string;
  judiciaryName: string;
  inviteeEmail: string;
  role: string;
  judiciaryId: number;
  divisionId: number;
  PhoneNumber: any;
  acceptTermsAndCondtion = false;

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.activatedRoute.queryParamMap.subscribe((data) => {
      if (data) {
        this.token = data.get("token").replace(/\s/g, "+");
        this.judiciaryName = data.get("JudiciaryName");
        this.inviteeEmail = data.get("InviteeEmail");
        this.role = data.get("Role");
        this.PhoneNumber = data.get("PhoneNumber");
        this.judiciaryId = +data.get("JudiciaryId");
        this.divisionId = +data.get("DivisionId");
      }
    });

    this.createForm();
  }

  get firstname() {
    return this.lawyerForm.get("firstname");
  }

  get lastname() {
    return this.lawyerForm.get("lastname");
  }

  get official() {
    return this.lawyerForm.get("official");
  }

  get personal() {
    return this.lawyerForm.get("personal");
  }

  get phoneNumber() {
    return this.lawyerForm.get("phoneNumber");
  }

  get password() {
    return this.lawyerForm.get("password");
  }

  get confirmPassword() {
    return this.lawyerForm.get("confirmPassword");
  }

  get stateId() {
    return this.lawyerForm.get("stateId");
  }

  ngOnInit(): void {
    this.invalidEmail = false;
    this.onLoadHide = true;
    this.btnSpinner = false;
  }

  toggleTermsAndCondition() {
    this.acceptTermsAndCondtion = !this.acceptTermsAndCondtion;
  }

  openSnackBar(message) {
    this.snackBar.open(message, "Close", {
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 3000,
    });
  }

  createForm() {
    this.lawyerForm = this.fb.group(
      {
        firstname: ["", Validators.required],
        lastname: ["", Validators.required],
        personal: [""],
        official: [
          { value: this.inviteeEmail, disabled: true },
          Validators.required,
        ],
        phoneNumber: [
          { value: `+234${this.PhoneNumber}`, disabled: true },
          [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            this.startsWithZero,
          ],
        ],
        password: ["", Validators.required],
        confirmPassword: ["", Validators.required],
      },
      {
        validator: PasswordValidation.MatchPassword, // your validation method
      }
    );
  }

  // VALIDATOR FUNCTION
  private startsWithZero(control: FormControl): { [key: string]: any } {
    const val = control.value;
    let startsWithZero = false;
    if (+val[0] === 0) {
      startsWithZero = true;
    }
    return startsWithZero ? { isLessThanMin: `${control.value}` } : null;
  }

  goBack() {
    this.location.back();
  }

  lawyers(data) {
    this.btnSpinner = true;

    const payload = {
      firstName: data.value.firstname,
      lastName: data.value.lastname,
      otherName: "",
      password: data.value.password,
      personalEmail: data.value.personal,
      userType: 1,
      token: this.token,
    };

    this.authService.newCaseRegistrar(payload).subscribe(
      () => {
        this.btnSpinner = false;
        this.router.navigate(["/login"], { queryParams: { id: this.role } });
      },
      (err) => {
        this.btnSpinner = false;
        this.openSnackBar(err.error);
      }
    );
  }

  // validateEmail() {
  //
  //   // this.emailCheckSpinner = true;
  //   this.authService.validateEmail(this.this..value)
  //     .toPromise().then(
  //       (data) => {
  //
  //         if (data) {
  //           this.invalidEmail = false;
  //           this.emailCheckSpinner = false;
  //
  //         } else {
  //         this.invalidEmail = true;
  //           this.messageBox.nativeElement.focus();
  //           // this.btnSpinner = false;
  //         }
  //       }, // success path
  //     ).catch(error => {
  //
  //       this.error = error; // error path
  //       this.emailCheckSpinner = false;
  //       this.invalidEmail = true;
  //       this.messageBox.nativeElement.focus();
  //     });
  // }
}
