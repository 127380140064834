import { Location } from "@angular/common";
import { Token } from "@angular/compiler/src/ml_parser/lexer";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { UserLoginDetail } from "src/app/models/input-models/login";
import {
  TempUserDetails,
  UserDetail,
} from "src/app/models/view-models/user-detail";
import { AuthService } from "src/app/services/auth.service";
import { CurrentUserService } from "src/app/services/factories/current-user.service";
import { isNullOrUndefined } from "util";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  user: UserDetail;
  responseData: Token;
  token: any;
  loginShowError;
  userProfile: UserDetail;
  userToken$: Observable<any>;
  loginBtnSpinner: boolean;
  isLoginError: boolean;
  emailVal: string;
  errorMessage: any;
  alreadyLoogedIn: boolean;
  status: string;
  showError = false;
  errMessage: string;

  @ViewChild("errorBox") errorBox: ElementRef;
  isLawyer: boolean;
  isRegistrar: boolean;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private currentUserService: CurrentUserService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private location: Location,
    private route: ActivatedRoute
  ) {
    this.emailVal = this.route.snapshot.paramMap.get("email");
    this.status = this.route.snapshot.queryParamMap.get("id");

    this.createForm();
  }

  get email() {
    return this.loginForm.get("email");
  }

  get password() {
    return this.loginForm.get("password");
  }

  forceLogout() {
    this.router.navigate(["/logout", this.email.value]);
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: [
        this.emailVal,
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      password: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.isLoginError = false;
    this.loginBtnSpinner = false;
    this.alreadyLoogedIn = false;
    this.isClicked();
  }

  isClicked() {
    if (
      this.status === "Registrar" ||
      this.status === "ComplianceOfficer" ||
      this.status === "Official"
    ) {
      this.isRegistrar = true;
    } else {
      this.isLawyer = true;
    }
  }

  login() {
    if (!this.loginForm.invalid) {
      this.loginBtnSpinner = true;

      const userLoginDetail: UserLoginDetail = {
        email: this.email.value,
        password: this.password.value,
      };

      userLoginDetail.loginRoleId = 3;
      if (this.status === "Registrar") {
        userLoginDetail.loginRoleId = 2;
      }
      if (this.status === "ComplianceOfficer") {
        userLoginDetail.loginRoleId = 4;
      }
      if (this.status === "Official") {
        userLoginDetail.loginRoleId = 5;
      }

      this.authService
        .signIn(userLoginDetail)
        .toPromise()
        .then((response) => {
          // tslint:disable-next-line: deprecation
          if (!isNullOrUndefined(response)) {
            const details: TempUserDetails = {
              emailAddress: response.body.emailAddress,
              firstName: response.body.firstName,
              refreshToken: response.body.refreshToken,
              token: response.body.token,
              role: response.body.role,
              userId: response.body.userId,
            };
            this.currentUserService.addTemporaryToken(details);
            this.router.navigate(["/dashboard"]);
          }
        })
        .catch((err) => {
          this.loginBtnSpinner = false;
          this.openSnackBar(err.error);
        });
    }
  }

  goBack() {
    this.location.back();
  }

  LoginEnter() {
    if (this.email.value !== "" && this.password.value !== "") {
      this.login();
    }
  }

  openSnackBar(message) {
    this.snackBar.open(message, "Ok", {
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 3000,
    });
  }

  notifySent(event) {
    if (event) {
      this.openSnackBar(
        "Done!.. We have sent an Email to you! It will inform you of the next instruction"
      );
    }
  }
}
