<!-- <div class="alert alert-danger alert-dismissible fade show error_notify">
    <button type="button" class="close close__err_notify" data-dismiss="alert">&times;</button>
    <strong>Danger!</strong> This alert box could indicate a dangerous or potentially negative action.
  </div> -->

<section class="section_block bg__off_white">
  <div class="container">
    <div class="row">
      <div class="offset-md-2 col-md-8">
        <div class="block_panel">
          <div class="row align-items-center">
            <div class="col-sm-4">
              <figure class="image_blob">
                <img src="assets/icons/email_confirm.svg" alt="confrim email" />
              </figure>
            </div>
            <div class="col-sm-8">
              <h2 *ngIf="!resend" class="info_title caps_upper font-bold">
                Just one more step...
              </h2>
              <p *ngIf="!resend" class="subtitle">
                Please verify your email address so you can sign in if you ever
                forget your password. We’ve sent a confirmation email to:
              </p>
              <h2 *ngIf="resend" class="info_title caps_upper font-bold">
                Done...
              </h2>
              <p *ngIf="resend">We’ve sent a confirmation email again to:</p>
              <div class="margin_top_sm">
                <strong class="color__primary">{{ registeredEmail }}</strong>
              </div>
              <div class="disclaimer_block">
                <p>
                  If you still don’t see it, you can check your spam folder, or
                  click here to
                  <button
                    [ngClass]="{ loading: btnSpinner }"
                    [disabled]="btnSpinner"
                    (click)="resendConfirmation()"
                    class="btn-link"
                  >
                    resend the confirmation email
                    <div class="loader_item">
                      <div class="cm_spinner"></div>
                    </div>
                  </button>
                </p>
                <div class="login__">
                  <a
                    [routerLink]="['/login']"
                    [queryParams]="{ id: 'Litigant' }"
                    class="btn-link"
                  >
                    Login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <app-anonymous-footer></app-anonymous-footer> -->
