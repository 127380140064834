import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { isNullOrUndefined } from "util";
import { ListModel } from "../../models/generic-models/lite-list.model";
import { GeneralService } from "../../services/general.service";
import { AuthService } from "../../services/services/auth.service";
import { ConfirmedValidator } from "../../shared/helpers/password-validator";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-registrar-registration",
  templateUrl: "./registrar-registration.component.html",
  styleUrls: ["./registrar-registration.component.scss"],
})
export class RegistrarRegistrationComponent implements OnInit {
  divisionData: ListModel[];
  status: string;
  courts: any[] = [];
  invalidEmail: boolean;
  error: string;
  allFieldNotField = false;
  // while page is loading first, before interaction, dont show notification
  onLoadHide: boolean;
  showError: boolean;
  btnSpinner: boolean;
  registrarForm: FormGroup;
  errMessage: string;

  token = "";
  judiciaryName = "";
  inviteeEmail = "";
  role = "";
  judiciaryId: number;
  divisionId: number;
  PhoneNumber = "";
  stateData = [];
  acceptTermsAndCondtion = false;

  genericPassword: string;

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private authService: AuthService,
    private genralService: GeneralService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.activatedRoute.queryParamMap.subscribe((data) => {
      if (data) {
        this.role = data.get("Role");

        if (this.role === "Registrar" || this.role === "Official") {
          this.token = data.get("token").replace(/\s/g, "+");
          this.judiciaryName = data.get("JudiciaryName");
          this.inviteeEmail = data.get("InviteeEmail");
          this.PhoneNumber = data.get("PhoneNumber");
          this.judiciaryId = +data.get("JudiciaryId");
          this.divisionId = +data.get("DivisionId");

          this.PhoneNumber = `${this.PhoneNumber}`;
        }
      }
    });

    this.createForm();
  }

  get firstname() {
    return this.registrarForm.get("firstname");
  }

  get lastname() {
    return this.registrarForm.get("lastname");
  }

  get official() {
    return this.registrarForm.get("official");
  }

  get personal() {
    return this.registrarForm.get("personal");
  }

  get phoneNumber() {
    return this.registrarForm.get("phoneNumber");
  }

  get password() {
    return this.registrarForm.get("password");
  }

  get enrollmentId() {
    return this.registrarForm.get("enrollmentId");
  }

  get courtId() {
    return this.registrarForm.get("courtId");
  }

  get confirmPassword() {
    return this.registrarForm.get("confirmPassword");
  }

  ngOnInit(): void {
    this.invalidEmail = false;
    this.onLoadHide = true;
    this.btnSpinner = false;

    if (this.role !== "Registrar") {
      this.stateList();
    }

    // this.divisionList(3);
  }

  toggleTermsAndCondition() {
    this.acceptTermsAndCondtion = !this.acceptTermsAndCondtion;
  }

  createForm() {
    this.registrarForm = this.fb.group(
      {
        firstname: ["", Validators.required],
        lastname: ["", Validators.required],
        personal: [""],
        official: [
          {
            value: this.inviteeEmail,
            disabled:
              this.role === "Registrar" || this.role === "Official"
                ? true
                : false,
          },
          Validators.required,
        ],
        phoneNumber: [
          {
            value: this.PhoneNumber,
            disabled:
              this.role === "Registrar" || this.role === "Official"
                ? true
                : false,
          },
          [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            this.startsWithZero,
          ],
        ],
        password: [null, Validators.required],
        enrollmentId: [""],
        divisionId: [this.divisionId],
        stateId: [
          "",
          this.role === "Registrar" || this.role === "Official"
            ? ""
            : Validators.required,
        ],
        confirmPassword: [null, Validators.required],
      },
      {
        validator: ConfirmedValidator("password", "confirmPassword"), // your validation method
      }
    );
  }

  divisionList(judicialId: number) {
    this.genralService.divisionList(3).subscribe((response) => {
      // tslint:disable-next-line: deprecation
      if (!isNullOrUndefined(response)) {
        this.divisionData = response;
      }
    });
  }

  // VALIDATOR FUNCTION
  private startsWithZero(control: FormControl): { [key: string]: any } {
    const val = control.value;
    let startsWithZero = false;
    if (+val[0] === 0) {
      startsWithZero = true;
    }
    return startsWithZero ? { isLessThanMin: `${control.value}` } : null;
  }

  addCourtData(data) {
    const exist = !!this.courts.find((x) => x.name === data.name);
    if (!exist) {
      this.courts.push(data);
    }
  }

  removeCourt(data) {
    this.courts.splice(+data, 1);
  }

  goBack() {
    this.location.back();
  }
  //
  // loadCourtList(data) {
  //
  //   //
  //
  // }

  stateList() {
    const countryId = 1;
    this.genralService.stateList(countryId).subscribe((response) => {
      // tslint:disable-next-line: deprecation
      if (!isNullOrUndefined(response)) {
        this.stateData = response;
      }
    });
  }

  openSnackBar(message) {
    this.snackBar.open(message, "Close", {
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 3000,
    });
  }
  // *ngIf="role === 'Registrar'"
  registratrar(data) {
    this.btnSpinner = true;

    if (this.role === "Registrar") {
      const user = {
        personalEmail: data.value.personal,
        password: data.value.password,
        firstName: data.value.firstname,
        lastName: data.value.lastname,
        otherName: "",
        token: this.token,
        userType: 1,
      };

      this.authService.Registrar(user).subscribe(
        (res) => {
          this.router.navigate(["/login"], {
            queryParams: { id: "Registrar" },
          });
          this.btnSpinner = false;
        },
        (err) => {
          this.btnSpinner = false;

          this.openSnackBar(err);
        }
      );
    } else if (this.role === "Official") {
      const user = {
        personalEmail: data.value.personal,
        password: data.value.password,
        firstName: data.value.firstname,
        lastName: data.value.lastname,
        otherName: "",
        token: this.token,
        userType: 5,
      };

      this.authService.Registrar(user).subscribe(
        (res) => {
          this.router.navigate(["/login"], {
            queryParams: { id: "Official" },
          });
          this.btnSpinner = false;
        },
        (err) => {
          this.btnSpinner = false;

          this.openSnackBar("An error occurred, please try again.");
        }
      );
    } else {
      const user = {
        firstName: data.value.firstname,
        lastName: data.value.lastname,
        officialEmail: data.value.official,
        personalEmail: data.value.personal || null,
        phoneNumber: data.value.phoneNumber,
        password: data.value.password,
        otherName: "",
        // tslint:disable-next-line: radix
        stateId: parseInt(data.value.stateId),
        enrolementNo: data.value.enrollmentId || "",
        userType: 0,
      };

      this.authService.register(user).subscribe(
        (res) => {
          this.btnSpinner = false;
          this.router.navigate([`/confirm-email/${user.officialEmail}`]);
        },
        (err) => {
          this.btnSpinner = false;
          this.openSnackBar(err);
        }
      );
    }
  }

  setGenericPassword(password: string): void {
    this.password.setValue(password);
    this.registrarForm.updateValueAndValidity();
  }

  setGenericConfirmPasswordPassword(password: string): void {
    this.confirmPassword.setValue(password);
    this.registrarForm.updateValueAndValidity();
  }

  // validateEmail() {
  //
  //   // this.emailCheckSpinner = true;
  //   this.authService.validateEmail(this.this..value)
  //     .toPromise().then(
  //       (data) => {
  //
  //         if (data) {
  //           this.invalidEmail = false;
  //           this.emailCheckSpinner = false;
  //
  //         } else {
  //         this.invalidEmail = true;
  //           this.messageBox.nativeElement.focus();
  //           // this.btnSpinner = false;
  //         }
  //       }, // success path
  //     ).catch(error => {
  //
  //       this.error = error; // error path
  //       this.emailCheckSpinner = false;
  //       this.invalidEmail = true;
  //       this.messageBox.nativeElement.focus();
  //     });
  // }
}
