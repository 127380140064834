import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ListModel } from "../models/generic-models/lite-list.model";

@Injectable({
  providedIn: "root",
})
export class GeneralService {
  Endpoints = environment;

  constructor(private httpClient: HttpClient) {}
  courtList(divisionId: number): Observable<ListModel[]> {
    const api = this.Endpoints.serverEndpoint + `Settings/court/${divisionId}`;
    return this.httpClient.get<ListModel[]>(api);
  }
  stateList(countryId: number): Observable<ListModel[]> {
    const api = this.Endpoints.serverEndpoint + `Settings/state/${countryId}`;
    return this.httpClient.get<ListModel[]>(api);
  }
  listOfStates(): Observable<any> {
    const api = this.Endpoints.serverEndpoint + `Settings/state`;
    return this.httpClient.get<any>(api);
  }
  divisionList(judiciaryId): Observable<ListModel[]> {
    //  judiciaryId = 1;
    const api =
      this.Endpoints.serverEndpoint + `Settings/Division/${judiciaryId}`;
    return this.httpClient.get<ListModel[]>(api);
  }
}
