import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PasswordControlComponent } from "./password-control/password-control.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";

@NgModule({
  declarations: [PasswordControlComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
  ],
  exports: [PasswordControlComponent],
})
export class GenericFormModule {}
