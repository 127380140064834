<!-- Modal -->
<div
  class="modal"
  id="updateAddress"
  tabindex="-1"
  role="dialog"
  aria-labelledby="updateProfileLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="updateProfileLabel">
          Create your admin profile
        </h5>
      </div>
      <div class="modal-body">
        <form [formGroup]="registerForm">
          <div class="u-grid-column-2">
            <div class="form-group">
              <label class="label" for="first__">FIRST NAME</label>
              <input
                type="text"
                class="form-control"
                id="first__"
                placeholder="E.g David"
                formControlName="firstname"
                autocomplete="none"
              />
              <p *ngIf="firstname.invalid">
                First name address is required
              </p>
            </div>
            <div class="form-group">
              <label class="label" for="lastname">LAST NAME</label>
              <input
                id="lastname"
                type="text"
                class="form-control"
                placeholder="E.g Aboye"
                formControlName="lastname"
                autocomplete="none"
              />
              <p *ngIf="lastname.invalid">
                Last name is required
              </p>
            </div>
          </div>
          <div class="form-group">
            <label class="label" for="other__">OTHER NAME</label>
            <input
              type="text"
              class="form-control"
              id="other__"
              placeholder="E.g davidisaboy@gmail.com"
              formControlName="otherName"
              autocomplete="none"
            />
          </div>

          <div class="u-grid-column-2">
            <div class="form-group">
              <label class="label" for="official">OFFICIAL EMAIL ADDRESS</label>
              <input
                type="email"
                class="form-control disabled_"
                id="official"
                placeholder="E.g davidisaboy@gmail.com"
                formControlName="official"
              />
            </div>
            <div class="form-group">
              <label class="label" for="personal__"
                >PERSONAL EMAIL ADDRESS</label
              >
              <input
                type="email"
                class="form-control"
                id="personal__"
                placeholder="E.g davidisaboy@gmail.com"
                formControlName="personal"
              />
              <p *ngIf="personal.invalid">
                Email address is required
              </p>
            </div>
          </div>

          <div class="form-group">
            <label class="label" for="phone_">PHONE NUMBER</label>
            <input
              type="tel"
              class="form-control disabled_"
              id="phone_"
              placeholder="E.g davidisaboy@gmail.com"
              formControlName="phoneNumber"
            />
            <p *ngIf="phoneNumber.invalid">
              Phone number is required
            </p>
          </div>
          <div class="form-group">
            <label class="label" for="pass1">PASSWORD</label>
            <input
              type="password"
              class="form-control"
              id="pass1"
              placeholder="Create a Password"
              formControlName="password"
              autocomplete="none"
            />
            <p *ngIf="password.invalid">
              Input your password
            </p>
          </div>
          <div class="form-group">
            <label class="label" for="con_">CONFIRM PASSWORD</label>
            <input
              type="password"
              class="form-control"
              id="con_"
              placeholder="Enter your password again"
              formControlName="confirmPassword"
              autocomplete="none"
            />
            <p *ngIf="confirmPassword.invalid">
              Password do not match
            </p>
          </div>
        </form>
      </div>
      <div class="modal-footer center">
        <button
          (click)="registerAdmin()"
          [disabled]="registerForm.invalid"
          class="btn__primary"
          [class.loading]="btnSpinner"
        >
          <div class="loader_item">
            <div class="cm_spinner"></div>
          </div>
          <span [class.invisible]="btnSpinner">CONTINUE</span>
        </button>
      </div>
    </div>
  </div>
</div>
