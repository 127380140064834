import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  token: any;
  email: any;
  isLoading: Boolean;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.route.queryParamMap.subscribe((data) => {
      if (data) {
        this.token = encodeURIComponent(data.get("token"));
        this.email = data.get("email");
      }
    });
  }

  get password() {
    return this.resetPasswordForm.get("password");
  }

  get confirmPassword() {
    return this.resetPasswordForm.get("confirmPassword");
  }

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group(
      {
        password: ["", Validators.required],
        confirmPassword: ["", [Validators.required]],
      },
      { validator: this.isMatch }
    );
  }

  // matching Validation
  isMatch(group: FormGroup): { [key: string]: any } {
    let password = group.get("password").value;
    let confirmPassword = group.get("confirmPassword").value;
    if (password !== confirmPassword && password) {
      return { notSame: true };
    } else {
      return null;
    }
  }

  openSnackBar(message) {
    this.snackBar.open(message, "Close", {
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 3000,
    });
  }

  resetPassword(value) {
    this.isLoading = true;
    const payload = {
      token: this.token,
      email: this.email,
      password: value.password,
      confirmPassword: value.confirmPassword,
    };
    this.authService.resetPassword(payload).subscribe(
      (data) => {
        this.isLoading = false;
        this.router.navigateByUrl("/home");
      },
      (error) => {
        this.isLoading = false;
        this.openSnackBar(error.error);
      }
    );
  }
}
