import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CurrentUserService } from "../../services/factories/current-user.service";
@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"],
})
export class LogoutComponent implements OnInit {
  constructor(
    private router: Router,
    private currentUserService: CurrentUserService
  ) {}

  ngOnInit(): void {
    const role = this.currentUserService.getTempUserProfile().role;
    this.currentUserService.logout();
    if (role === "SuperAdmin" || role === "Administrator") {
      this.router.navigate(["/login/admin"]);
    } else {
      this.router.navigate(["/home"]);
    }
  }
}
