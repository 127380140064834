<nav class="nav">
  <ul class="nav__list">
    <li class="nav__list-item">
      <a class="nav__link" routerLink="/home">
        Home
      </a>
    </li>
    <li class="nav__list-item">
      <a
        aria-haspopup="true"
        class="nav__link dropdown-toggle"
        data-toggle="dropdown"
      >
        <span class="nav__icon">
          <svg
            fill="none"
            height="20"
            viewBox="0 0 18 20"
            width="18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 15V12H0V8H7V5L12 10L7 15ZM7 0H16C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V18C18 18.5304 17.7893 19.0391 17.4142 19.4142C17.0391 19.7893 16.5304 20 16 20H7C6.46957 20 5.96086 19.7893 5.58579 19.4142C5.21071 19.0391 5 18.5304 5 18V16H7V18H16V2H7V4H5V2C5 1.46957 5.21071 0.960859 5.58579 0.585786C5.96086 0.210714 6.46957 0 7 0Z"
              fill="#908F8F"
            />
          </svg>
        </span>
        Sign Up
      </a>
      <div class="dropdown-menu">
        <a class="dropdown-item" routerLink="/lawyer">As a Lawyer</a>
        <a class="dropdown-item" routerLink="/registrar">As a Registrar</a>
      </div>
    </li>
  </ul>
</nav>

<div class="main__container">
  <div class="form__container">
    <h3 class="form__header">Reset Password</h3>
    <div class="form__body">
      <form [formGroup]="resetPasswordForm">
        <div class="form-group">
          <label class="label" for="exampleInputPassword1">New Password</label>
          <div class="input__appendContainer">
            <input
              class="form-control"
              formControlName="password"
              id="exampleInputPassword1"
              placeholder="Enter your password"
              type="password"
            />
            <!-- <span class="input__append">
                                  <svg fill="none" height="15" viewBox="0 0 24 15" width="24" xmlns="http://www.w3.org/2000/svg">
                                      <g opacity="0.5">
                                      <path
                                        d="M23.75 6.76982C20.15 2.26982 16.25 0.0698242 12.15 0.0698242C10.55 0.0698242 8.85 0.469824 7.25 1.16982C5.95 1.76982 4.65 2.46982 3.45 3.46982C1.35 5.16982 0.15 6.76982 0.15 6.86982C-0.05 7.16982 -0.05 7.66982 0.15 7.96982C3.75 12.4698 7.65 14.6698 11.75 14.6698C13.35 14.6698 15.05 14.2698 16.65 13.5698C17.95 12.9698 19.15 12.2698 20.45 11.2698C22.55 9.56982 23.75 7.96982 23.75 7.86982C24.05 7.56982 24.05 7.16982 23.75 6.76982ZM19.35 9.86982C17.65 11.2698 14.95 12.8698 11.85 12.8698C8.45 12.8698 5.15 10.9698 2.05 7.36982C2.45 6.76982 3.35 5.76982 4.55 4.86982C6.25 3.46982 8.95 1.86982 12.05 1.86982C15.45 1.86982 18.75 3.76982 21.85 7.36982C21.45 7.96982 20.55 8.96982 19.35 9.86982Z"
                                        fill="#2A4F77"/>
                                      <path
                                        d="M15.1501 6.46978C14.6501 6.46978 14.2501 6.86978 14.2501 7.36978C14.2501 8.66978 13.1501 9.76978 11.8501 9.76978C10.5501 9.76978 9.4501 8.66978 9.4501 7.36978C9.4501 6.06978 10.5501 4.96978 11.8501 4.96978C12.3501 4.96978 12.7501 4.56978 12.7501 4.06978C12.7501 3.56978 12.4501 3.26978 11.9501 3.26978C9.6501 3.26978 7.8501 5.16978 7.8501 7.36978C7.8501 9.56978 9.7501 11.4698 11.9501 11.4698C14.1501 11.4698 16.0501 9.56978 16.0501 7.36978C16.0501 6.86978 15.6501 6.46978 15.1501 6.46978Z"
                                        fill="#2A4F77"/>
                                      </g>
                                  </svg>
                              </span> -->
          </div>
          <em
            *ngIf="password.invalid && password.dirty"
            class="help-block text-error"
          >
            New password is Required
          </em>
        </div>
        <div class="form-group">
          <label class="label" for="exampleInputPassword2"
            >Confirm New Password</label
          >
          <div class="input__appendContainer">
            <input
              class="form-control"
              formControlName="confirmPassword"
              id="exampleInputPassword2"
              placeholder="Enter your password"
              type="password"
            />
            <!-- <span class="input__append">
                            <svg fill="none" height="15" viewBox="0 0 24 15" width="24" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.5">
                                <path
                                  d="M23.75 6.76982C20.15 2.26982 16.25 0.0698242 12.15 0.0698242C10.55 0.0698242 8.85 0.469824 7.25 1.16982C5.95 1.76982 4.65 2.46982 3.45 3.46982C1.35 5.16982 0.15 6.76982 0.15 6.86982C-0.05 7.16982 -0.05 7.66982 0.15 7.96982C3.75 12.4698 7.65 14.6698 11.75 14.6698C13.35 14.6698 15.05 14.2698 16.65 13.5698C17.95 12.9698 19.15 12.2698 20.45 11.2698C22.55 9.56982 23.75 7.96982 23.75 7.86982C24.05 7.56982 24.05 7.16982 23.75 6.76982ZM19.35 9.86982C17.65 11.2698 14.95 12.8698 11.85 12.8698C8.45 12.8698 5.15 10.9698 2.05 7.36982C2.45 6.76982 3.35 5.76982 4.55 4.86982C6.25 3.46982 8.95 1.86982 12.05 1.86982C15.45 1.86982 18.75 3.76982 21.85 7.36982C21.45 7.96982 20.55 8.96982 19.35 9.86982Z"
                                  fill="#2A4F77"/>
                                <path
                                  d="M15.1501 6.46978C14.6501 6.46978 14.2501 6.86978 14.2501 7.36978C14.2501 8.66978 13.1501 9.76978 11.8501 9.76978C10.5501 9.76978 9.4501 8.66978 9.4501 7.36978C9.4501 6.06978 10.5501 4.96978 11.8501 4.96978C12.3501 4.96978 12.7501 4.56978 12.7501 4.06978C12.7501 3.56978 12.4501 3.26978 11.9501 3.26978C9.6501 3.26978 7.8501 5.16978 7.8501 7.36978C7.8501 9.56978 9.7501 11.4698 11.9501 11.4698C14.1501 11.4698 16.0501 9.56978 16.0501 7.36978C16.0501 6.86978 15.6501 6.46978 15.1501 6.46978Z"
                                  fill="#2A4F77"/>
                                </g>
                            </svg>
                        </span> -->
          </div>
          <em
            *ngIf="confirmPassword.invalid && confirmPassword.dirty"
            class="help-block text-error"
          >
            Confirm Password is required
          </em>
          <em
            *ngIf="
              resetPasswordForm.errors?.notSame &&
              !confirmPassword.invalid &&
              confirmPassword.dirty
            "
            class="help-block text-error"
          >
            Password Doesn't match
          </em>
        </div>
      </form>
      <button
        (click)="resetPassword(resetPasswordForm.value)"
        [class.loading]="isLoading"
        [disabled]="isLoading || resetPasswordForm.invalid"
        class="btn__primary-stretch"
      >
        <div class="loader_item">
          <div class="cm_spinner"></div>
        </div>
        <span [class.invisible]="isLoading">Reset Password</span>
      </button>
    </div>
  </div>
</div>
