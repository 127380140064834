import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { GeneralService } from "../services/general.service";
import { AuthenticationRoutingModule } from "./authentication-routing.module";
import { ConfirmAccountComponent } from "./confirm-account/confirm-account.component";
import { ConfirmEmailComponent } from "./confirm-email/confirm-email.component";
import { EmailConfirmedComponent } from "./email-confirmed/email-confirmed.component";
import { ForgotPasswordModalComponent } from "./forgot-password-modal/forgot-password-modal.component";
import { LoginComponent } from "./login/login.component";
import { LogoutComponent } from "./logout/logout.component";
import { RegistrarRegistrationComponent } from "./registrar-registration/registrar-registration.component";
import { RegistrationComponent } from "./registration/registration.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { JoinComponent } from "./join/join.component";
import { AdminLoginComponent } from "./admin-login/admin-login.component";
import { FirmRegistrationComponent } from "./firm-registration/firm-registration.component";
import { AdminRegistrationComponent } from "./admin-registration/admin-registration.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { LayoutComponent } from "./layout/layout.component";
import { GenericFormModule } from "../featured-module/generic-form/generic-form.module";

@NgModule({
  declarations: [
    LoginComponent,
    RegistrationComponent,
    RegistrarRegistrationComponent,
    EmailConfirmedComponent,
    LogoutComponent,
    JoinComponent,
    ForgotPasswordModalComponent,
    ResetPasswordComponent,
    ConfirmEmailComponent,
    ConfirmAccountComponent,
    AdminLoginComponent,
    FirmRegistrationComponent,
    AdminRegistrationComponent,
    LayoutComponent,
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatSnackBarModule,
    GenericFormModule,
  ],
  providers: [AuthService, GeneralService],
})
export class AuthenticationModule {}
