import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DocumentService } from "../../services/document.service";
import * as html2pdf from "html2pdf.js";

@Component({
  selector: "app-receipt",
  templateUrl: "./receipt.component.html",
  styleUrls: ["./receipt.component.scss"],
})
export class ReceiptComponent implements OnInit {
  documentId: number;
  receiptId: any;
  theDocument: any = null;
  totalFee = 0;
  paymentCharge = 0;
  fillingCharge = 0;
  @ViewChild("print_content", { static: false }) pdfTable: ElementRef;
  isPageLoading: boolean;
  isLoading: boolean;

  constructor(
    private documentService: DocumentService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      this.documentId = +params.id;
      this.receiptId = params.receiptId;
    });
  }

  ngOnInit(): void {
    this.getDocumentData();
  }

  getDocumentData() {
    this.isPageLoading = true;
    this.documentService.getADocument(this.documentId).subscribe(
      (data) => {
        this.isPageLoading = false;
        if (data) {
          this.theDocument = { ...data };

          if (
            this.theDocument?.payment?.totalAmountPaid >
            this.theDocument?.payment?.amountPaid
          ) {
            this.theDocument.payment.amountPaid =
              this.theDocument.payment.amountPaid / 1.215;
            this.fillingCharge = 0.2 * this.theDocument.payment.amountPaid;
            this.paymentCharge =
              (1.5 / 100) * this.theDocument.payment.amountPaid;

            this.totalFee =
              this.theDocument.payment.amountPaid +
              this.fillingCharge +
              this.paymentCharge;
          } else {
            this.paymentCharge =
              (1.5 / 100) *
              this.theDocument.fees.reduce(
                (prev, next) => prev + next.price,
                0
              ) *
              100;
            this.totalFee = this.theDocument.payment.amountPaid;
          }
        }
      },
      () => {
        this.isPageLoading = false;
      }
    );
  }

  printReceipt() {
    window.print();
  }

  downloadReceipt() {
    window.scrollTo(0, 0);
    const options = {
      filename: `receipt`,
      image: { type: "png" },
      html2canvas: {
        quality: 1,
        scale: 1,
      },
      jsPDF: {
        orientation: "landscape",
      },
    };

    const content = document.getElementById("print-content");

    html2pdf().from(content).set(options).save();
    let pdfByteData = "";

    html2pdf()
      .from(content)
      .toPdf()
      .output("datauristring")
      .then((pdfUri) => {
        pdfByteData = pdfUri;
      });
  }

  gethtml2pdfUri() {
    window.scrollTo(0, 0);
    const content = document.getElementById("print-content");
    return html2pdf().from(content).toPdf().output("datauristring");
  }

  async proceed() {
    this.isLoading = true;
    let uri = await this.gethtml2pdfUri();
    uri = uri.split("base64,")[1];
    const payload = {
      documentId: this.documentId,
      fileName: "Payment Receipt",
      fileBinary: uri,
      fileExtension: "pdf",
    };
    this.documentService.sendReceipt(payload).subscribe(
      () => {
        this.isLoading = false;
        this.router.navigateByUrl("/dashboard");
      },
      () => {
        this.isLoading = false;
      }
    );
  }
}
