<div [class.loading]="isPageLoading" class="page_loader">
  <div class="loader_content">
    <img alt="Page Loader" class="loaderImage" src="./assets/icons/bars.svg" />
    <p class="font-md">loading...</p>
  </div>
</div>

<header>
  <div class="logo">
    <img alt="coat of arms" src="assets/img/Ogun-state-sm.png" />
  </div>
  <div class="body_">
    <section class="body__message">
      Payment Successful
    </section>
    <a class="body__close" routerLink="/dashboard">
      <span><i class="fa fa-close"></i></span>
      <span>Exit</span>
    </a>
  </div>
</header>

<div class="wrapper_">
  <div id="print-content">
    <div class="detail">
      <div class="state_">
        <div class="coat_of_arms">
          <img alt="coat of arms" src="assets/img/ogun-state-md.png" />
        </div>
        <div class="state__info __1">
          <p>OGUN STATE JUDICIARY</p>
          <p>
            <span>OGUN STATE HIGH COURT OF JUSTICE, </span>
            <span>High Court Complex, Kobape Road</span>
            <span>Ogun State, Nigeria.</span>
          </p>
          <!-- <p>Nigeria</p> -->
        </div>
      </div>
      <div class="date_">
        <div class="state__info">
          <p>Receipt</p>
          <p>
            {{
            theDocument?.awaitingPaymentVerificationDate | date: "mediumDate"
            }}
          </p>
          <p>{{ receiptId }}</p>
        </div>
      </div>
    </div>

    <div class="payee_">
      <div class="ind__">
        <p>Received From</p>
        <p>{{ theDocument?.litigant }}</p>
      </div>
      <div class="user__info">
        <p>
          Received by
        </p>
        <p>
          <!-- {{ theDocument?.registrarInCharge }} -->
        </p>
        <p>
          <span>OGUN STATE HIGH COURT OF JUSTICE, </span>
          <span>High Court Complex, Kobape Road </span>
          <span>Nigeria</span>
        </p>
      </div>
    </div>

    <div class="price_table">
      <div class="table__">
        <div class="table_head">
          <p>Description</p>
          <p>Qty</p>
          <p>Price</p>
        </div>
        <div *ngIf="
            theDocument?.payment?.totalAmountPaid ===
            theDocument?.payment?.amountPaid
          ">
          <div class="table_data" *ngFor="let item of theDocument?.fees">
            <p>{{ item.type }}</p>
            <p *ngIf="item.type !== 'Printing Fee'" [class.invisible]="!item.count">
              {{ item.count }}
            </p>
            <p *ngIf="item.type === 'Printing Fee'">{{ item.count }} page(s)</p>
            <p>{{ item.price | currency: "NGN" }}</p>
          </div>
        </div>
        <div *ngIf="
            theDocument?.payment?.totalAmountPaid >
            theDocument?.payment?.amountPaid
          ">
          <div class="table_data">
            <p>Balance Paid</p>
            <p class="invisible">
              0
            </p>
            <p>
              {{ this.theDocument.payment.amountPaid / 100 | currency: "&#8358;" }}
            </p>
          </div>
          <div class="table_data">
            <p>
              Online Filing Surcharge
            </p>
            <p class="invisible">
              0
            </p>
            <p>
              {{
              fillingCharge / 100 | currency: "&#8358;"
              }}
            </p>
          </div>
        </div>
        <div class="table_data" *ngIf="paymentCharge > 0">
          <p>
            1.5% Online Payment Charge
          </p>
          <p class="invisible">
            0
          </p>
          <p>
            {{
            paymentCharge / 100 | currency: "&#8358;"
            }}
          </p>
        </div>
        <div class="table_footer">
          <div class="table_data">
            <p class="__hidden"></p>
            <p>Total Amount Paid</p>
            <p>{{ totalFee / 100 | currency: "NGN" }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="proceed__">
    <button class="btn btn-warning" (click)="proceed()" [disabled]="isLoading" [class.loading]="isLoading">
      <div class="loader_item">
        <div class="cm_spinner"></div>
      </div>
      <span [class.invisible]="isLoading">PROCEED</span>
    </button>
  </div>
</div>

<div class="footer">
  <div class="__left" style="cursor: pointer;">
    <section class="__icon_left" (click)="downloadReceipt()">
      <span><i class="fa fa-download"></i></span>
      <span>Download</span>
    </section>
    <a class="__icon_left" (click)="printReceipt()">
      <span><i class="fa fa-print"></i></span>
      <span>Print</span>
    </a>
    <!-- <section class="__icon_left">
      <span><i class="fa fa-pencil"></i></span>
      <span>Request Signature</span>
    </section> -->
  </div>

  <div class="__right">
    <section class="__icon_right">
      <span><i class="fa fa-close"></i></span>
      <span>Exit</span>
    </section>
  </div>
</div>
