import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../services/services/auth.service";

@Component({
  selector: "app-join",
  templateUrl: "./join.component.html",
  styleUrls: ["./join.component.scss"],
})
export class JoinComponent implements OnInit {
  token: string;
  judiciaryName: string;
  inviteeEmail: string;
  role: string;
  judiciaryId: any;
  divisionId: any;
  PhoneNumber: any;

  params: any;

  constructor(
    private location: Location,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activatedRoute.queryParamMap.subscribe((data) => {
      if (data) {
        this.params = data;
        this.role = data.get("Role");
        this.token = data.get("token");
        this.divisionId = data.get("DivisionId");
        this.inviteeEmail = data.get("InviteeEmail");
        this.judiciaryName = data.get("JudiciaryName");
        this.judiciaryId = data.get("JudiciaryId");
        this.PhoneNumber = data.get("PhoneNumber");
      }
    });
  }

  /*
DivisionId: "1"
InviteeEmail: "bsmtibadagry@gmail.com"
JudiciaryId: "3"
JudiciaryName: "Lagos State Judiciary"
Role: "Administrator"
token

  */
  ngOnInit(): void {
    const queryData = {
      DivisionId: this.divisionId,
      InviteeEmail: this.inviteeEmail,
      JudiciaryId: this.judiciaryId,
      JudiciaryName: this.judiciaryName,
      Role: this.role,
      PhoneNumber: this.PhoneNumber,
      token: this.token,
    };

    if (this.role === "SuperAdmin") {
      this.router.navigate(["/firm-reg"], { queryParams: queryData });
    } else if (this.role === "Administrator") {
      this.router.navigate(["/register/admin"], { queryParams: queryData });
    } else if (this.role === "Registrar") {
      this.router.navigate(["/register"], { queryParams: queryData });
    } else if (this.role === "Official") {
      this.router.navigate(["/register"], { queryParams: queryData });
    } else if (this.role === "NewcaseRegistrar") {
      this.router.navigate(["/case-register"], { queryParams: queryData });
    } else if (this.role === "ComplianceOfficer") {
      this.router.navigate(["/case-register"], { queryParams: queryData });
    } else {
      // this.router.navigateByUrl('/');
    }
  }
}
