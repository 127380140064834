import {
  Component,
  NgZone,
  Renderer2,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {
  RouterEvent,
  NavigationStart,
  Router,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from "@angular/router";
import { Observable, of } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "e-registry";

  @ViewChild("spinnerElement")
  spinnerElement: ElementRef;

  showpageLoader$: Observable<boolean> = of(true);

  isOnline$: Observable<boolean>;

  constructor(private router: Router, private ngZone: NgZone) {
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  // Shows and hides the loading spinner during RouterEvent changes
  private navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.ngZone.runOutsideAngular(() => {
        this.showpageLoader$ = of(true);
      });
    }
    if (event instanceof NavigationEnd) {
      this._hideSpinner();
    }
    if (event instanceof NavigationCancel) {
      this._hideSpinner();
    }
    if (event instanceof NavigationError) {
      this._hideSpinner();
    }
  }

  private _hideSpinner(): void {
    this.ngZone.runOutsideAngular(() => {
      this.showpageLoader$ = of(false);
    });
  }
}
