import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth-guard.service";
import { HomeGuard } from "./guards/home-guard.service";
import { AdminGuardService } from "./services/admin-guard/admin-guard.service";
import { UserGuardService } from "./services/user-guard/user-guard.service";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: "home",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
    canActivate: [HomeGuard],
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((r) => r.DashboardModule),
    canActivate: [AuthGuard],
    canLoad: [UserGuardService],
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./admin/admin.module").then((r) => r.AdminModule),
    canLoad: [AdminGuardService],
  },
  {
    path: "**",
    loadChildren: () =>
      import("./error-pages/error-pages.module").then(
        (m) => m.ErrorPagesModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
