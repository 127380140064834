<ng-container *ngIf="showInputValidators; else noValidators">
  <mat-form-field appearance="outline">
    <input matInput [(ngModel)]="control" [placeholder]="PasswordPlaceholder" [type]="toggleText ? 'password' : 'text'"
      (ngModelChange)="updateFormControl($event)" />
    <button mat-icon-button matSuffix (click)="toggleText = !toggleText" [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="toggleText" type="button">
      <mat-icon>{{ toggleText ? "visibility_off" : "visibility" }}</mat-icon>
    </button>
  </mat-form-field>
  <div class="popover-password" [hidden]="!showValidator">
    <p><span id="result"></span></p>
    <div class="progress">
      <div id="password-strength" class="progress-bar" role="progressbar" aria-valuenow="40" aria-valuemin="0"
        aria-valuemax="100" style="width:0%">
      </div>
    </div>
    <ul class="list-unstyled">
      <li>
        <span class="low-upper-case">
          <mat-icon #lowUpperCase>warning</mat-icon>
          Lowercase & Uppercase
        </span>
      </li>
      <li>
        <span class="one-number">
          <mat-icon>warning</mat-icon>
          Number (0-9)
        </span>
      </li>
      <li>
        <span class="one-special-char">
          <mat-icon>warning</mat-icon>
          Special Character (!@#$%^&*)
        </span>
      </li>
      <li>
        <span class="eight-character">
          <mat-icon>warning</mat-icon>
          Atleast 8 Character
        </span>
      </li>
    </ul>
  </div>
</ng-container>

<ng-template #noValidators>
  <mat-form-field appearance="outline">
    <input matInput [(ngModel)]="control" [placeholder]="PasswordPlaceholder" [type]="toggleText ? 'password' : 'text'"
      (ngModelChange)="updateFormControl($event)" />
    <button mat-icon-button matSuffix (click)="toggleText = !toggleText" [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="toggleText" type="button">
      <mat-icon>{{ toggleText ? "visibility_off" : "visibility" }}</mat-icon>
    </button>
  </mat-form-field>
</ng-template>
