import { Injectable } from "@angular/core";

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { tap } from "rxjs/operators";
import { CurrentUserService } from "../services/factories/current-user.service";
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private currentUserService: CurrentUserService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const headers: any = {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
    };

    if (request.url.includes("login")) {
      const loginRequest = request.clone({
        setHeaders: headers,
      });
      return next.handle(loginRequest);
    }

    const withoutAuth = request.headers.get("Without-Auth");
    if (withoutAuth) {
      return this.continueWithoutAuth(request, next, headers);
    }
    headers["Authorization"] = `Bearer ${this.currentUserService.getToken()}`;
    const newRequest = request.clone({ setHeaders: headers });
    return next.handle(newRequest).pipe(
      tap(
        () => {},
        (err) => {
          if (err instanceof HttpErrorResponse) {
            this.handleError(err);
          }
        }
      )
    );
  }

  private continueWithoutAuth(
    request: HttpRequest<any>,
    next: HttpHandler,
    globalHeaders: any
  ): Observable<any> {
    const headers = request.headers.delete("Without-Auth");
    request = request.clone({
      setHeaders: globalHeaders,
    });
    return next.handle(request);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      if (error.status === 401) {
        this.router.navigate([
          `/logout/${
            this.currentUserService.getTempUserProfile().emailAddress
          }`,
        ]);
      }
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }

    return throwError(error);
  }
}
