<app-forgot-password-modal (onSent)="notifySent($event)"></app-forgot-password-modal>
<div class="login">
  <div class="login__leftPane"></div>
  <div class="login__rightPane">

    <div class="login_rightPaneWrapper">
      <div class="login__rightPane--header">
        <span (click)="goBack()" class="item-icon circle">
          <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.2349 0.764455C19.1189 0.648349 18.9813 0.556237 18.8297 0.49339C18.6782 0.430542 18.5157 0.398193 18.3517 0.398193C18.1876 0.398193 18.0251 0.430542 17.8736 0.49339C17.722 0.556237 17.5844 0.648349 17.4685 0.764455L9.99965 8.23325L2.55006 0.764455C2.43414 0.648349 2.29647 0.556237 2.14492 0.49339C1.99337 0.430542 1.83092 0.398193 1.66686 0.398193C1.50279 0.398193 1.34034 0.430542 1.18879 0.49339C1.03724 0.556237 0.89957 0.648349 0.783655 0.764455C0.667549 0.88037 0.575437 1.01804 0.51259 1.16959C0.449742 1.32114 0.417393 1.48359 0.417393 1.64766C0.417393 1.81172 0.449742 1.97417 0.51259 2.12572C0.575437 2.27727 0.667549 2.41494 0.783655 2.53086L8.23325 9.99965L0.764455 17.4493C0.648349 17.5652 0.556237 17.7028 0.49339 17.8544C0.430542 18.0059 0.398193 18.1684 0.398193 18.3325C0.398193 18.4965 0.430542 18.659 0.49339 18.8105C0.556237 18.9621 0.648349 19.0997 0.764455 19.2157C0.88037 19.3318 1.01804 19.4239 1.16959 19.4867C1.32114 19.5496 1.48359 19.5819 1.64766 19.5819C1.81172 19.5819 1.97417 19.5496 2.12572 19.4867C2.27727 19.4239 2.41494 19.3318 2.53086 19.2157L9.99965 11.7661L17.4493 19.2349C17.5652 19.351 17.7028 19.4431 17.8544 19.5059C18.0059 19.5688 18.1684 19.6011 18.3325 19.6011C18.4965 19.6011 18.659 19.5688 18.8105 19.5059C18.9621 19.4431 19.0997 19.351 19.2157 19.2349C19.3318 19.1189 19.4239 18.9813 19.4867 18.8297C19.5496 18.6782 19.5819 18.5157 19.5819 18.3517C19.5819 18.1876 19.5496 18.0251 19.4867 17.8736C19.4239 17.722 19.3318 17.5844 19.2157 17.4685L11.7661 9.99965L19.2349 2.55006C19.3539 2.43381 19.4484 2.29495 19.513 2.14162C19.5776 1.9883 19.6108 1.82362 19.6108 1.65726C19.6108 1.49089 19.5776 1.32621 19.513 1.17289C19.4484 1.01956 19.3539 0.880699 19.2349 0.764455Z"
              fill="#959595" opacity="0.5" />
          </svg>
        </span>
      </div>
      <div class="login__content">
        <h4 class="login__header">
          Log in as {{ status == "Official" ? "a" : "a" }}

          {{
          status == "Litigant"
          ? "Litigant / Counsel"
          : status == "ComplianceOfficer"
          ? "Compliance-Officer"
          : status == "NewcaseRegistrar"
          ? "Registrar"
          : "Registrar"
          }}
          <!-- {{ status == "ComplianceOfficer" ? "Compliance-Officer" : status }} -->
        </h4>
        <div class="login__help">
          <span *ngIf="isLawyer">
            Don’t have an account?
            <a class="login__help--link" routerLink="/register" [queryParams]="{ Role: 'Litigant' }">Sign up
              here</a></span>
        </div>
        <form [formGroup]="loginForm" novalidate>
          <div class="form-group">
            <label class="label" for="useremail">Email Address</label>
            <input (keydown.enter)="LoginEnter()" class="form-control" formControlName="email" id="useremail"
              name="useremail" placeholder="Email address" type="email" />
            <p *ngIf="email.hasError('pattern') && !email.hasError('required')" class="help-block text-error">
              Please provide a valid email address
            </p>
            <p *ngIf="email.errors?.required && (email.dirty || email.touched)" class="help-block text-error">
              Email is required
            </p>
          </div>
          <div class="form-group">
            <label class="label" for="password">Password</label>
            <div class="input__appendContainer">
              <input (keydown.enter)="LoginEnter()" class="form-control" formControlName="password" name="password"
                placeholder="Enter your password" type="password" />
              <!-- <span class="input__append">
                    <svg fill="none" height="15" viewBox="0 0 24 15" width="24" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.5">
                        <path
                          d="M23.75 6.76982C20.15 2.26982 16.25 0.0698242 12.15 0.0698242C10.55 0.0698242 8.85 0.469824 7.25 1.16982C5.95 1.76982 4.65 2.46982 3.45 3.46982C1.35 5.16982 0.15 6.76982 0.15 6.86982C-0.05 7.16982 -0.05 7.66982 0.15 7.96982C3.75 12.4698 7.65 14.6698 11.75 14.6698C13.35 14.6698 15.05 14.2698 16.65 13.5698C17.95 12.9698 19.15 12.2698 20.45 11.2698C22.55 9.56982 23.75 7.96982 23.75 7.86982C24.05 7.56982 24.05 7.16982 23.75 6.76982ZM19.35 9.86982C17.65 11.2698 14.95 12.8698 11.85 12.8698C8.45 12.8698 5.15 10.9698 2.05 7.36982C2.45 6.76982 3.35 5.76982 4.55 4.86982C6.25 3.46982 8.95 1.86982 12.05 1.86982C15.45 1.86982 18.75 3.76982 21.85 7.36982C21.45 7.96982 20.55 8.96982 19.35 9.86982Z"
                          fill="#2A4F77"/>
                        <path
                          d="M15.1501 6.46978C14.6501 6.46978 14.2501 6.86978 14.2501 7.36978C14.2501 8.66978 13.1501 9.76978 11.8501 9.76978C10.5501 9.76978 9.4501 8.66978 9.4501 7.36978C9.4501 6.06978 10.5501 4.96978 11.8501 4.96978C12.3501 4.96978 12.7501 4.56978 12.7501 4.06978C12.7501 3.56978 12.4501 3.26978 11.9501 3.26978C9.6501 3.26978 7.8501 5.16978 7.8501 7.36978C7.8501 9.56978 9.7501 11.4698 11.9501 11.4698C14.1501 11.4698 16.0501 9.56978 16.0501 7.36978C16.0501 6.86978 15.6501 6.46978 15.1501 6.46978Z"
                          fill="#2A4F77"/>
                        </g>
                    </svg>
                </span> -->
            </div>
            <p *ngIf="
                password.errors?.required &&
                (password.dirty || password.touched)
              " class="help-block text-error">
              Please enter a password
            </p>
          </div>
          <div class="btn__footer">
            <button (click)="login()" [disabled]="loginForm.invalid || loginBtnSpinner"
              [ngClass]="{ loading: loginBtnSpinner }" class="btn__primary has_loader">
              <div class="loader_item">
                <div class="cm_spinner"></div>
              </div>
              Continue
            </button>
            <button class="btn__text btn__text--primary" data-target="#forgotPasswordModal" data-toggle="modal">
              Forgot Password?
            </button>
          </div>
          <!-- <button type="submit" class="btn__primary">Continue</button> -->
        </form>
      </div>
    </div>
  </div>
</div>
