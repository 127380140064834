import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Token } from "@angular/compiler/src/ml_parser/lexer";
import { Injectable } from "@angular/core";

import { Observable, throwError } from "rxjs";

import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
// import { RegisterInvitedUser } from '../models/parse-model/register-invited-user';
// import { AppConfigService } from '../shared/helpers/app-config.service';
import { UserLoginDetail } from "../models/input-models/login";
import { AccountModel } from "../models/input-models/register";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  Endpoints = environment;

  constructor(private httpClient: HttpClient) {}

  public signIn(user: UserLoginDetail): Observable<any> {
    // const requestUrl = baseApi + 'Account/Login';
    const requestUrl =
      this.Endpoints.serverEndpoint + this.Endpoints.loginEndPoint;
    // const data = 'email=' + user.email + '&password=' + user.password;
    return this.httpClient.post<any>(requestUrl, user, { observe: "response" });
  }

  public signOut(email: string): Observable<any> {
    // const requestUrl = baseApi + 'Account/Login';
    const requestUrl =
      this.Endpoints.serverEndpoint + `account/${email}/logout`;
    // const data = 'email=' + user.email + '&password=' + user.password;
    return this.httpClient.get<any>(requestUrl);
  }

  // public validateEmail(e) {
  //   // yet to complete endpoint url
  //   const requestUrl = this.Endpoints.serverEndpoint + this.Endpoints.validateEmail + e;
  //   return this.httpClient.get<any>(requestUrl, e);
  // }

  register(user: AccountModel) {
    // yet to complete endpoint url
    const requestUrl =
      this.Endpoints.serverEndpoint + this.Endpoints.registerEndPoint;
    return this.httpClient
      .post(requestUrl, user)
      .pipe(catchError(this.handleError));
  }

  newCaseRegistrar(payload): Observable<any> {
    const requestUrl = this.Endpoints.serverEndpoint + "account/register";
    return this.httpClient.post<any>(requestUrl, payload);
  }

  // public confirmEmail(token: string) {
  //   const requestUrl = this.config.getConfig().serverEndpoint + this.Endpoints.confirmEmailEndPoint + '?token=' + token;
  //   return this.httpClient.get<Observable<any>>(requestUrl);
  // }

  // public resendConfirmation(email: string) {
  //   const requestUrl = this.config.getConfig().serverEndpoint + `firm/ResendComfirmation`;
  //   return this.httpClient.post<Observable<any>>(requestUrl, { email });
  // }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }

  public forgotPassword(email): Observable<any> {
    const requestUrl =
      this.Endpoints.serverEndpoint + `account/forgot-password`;
    return this.httpClient.post<Token>(requestUrl, email);
  }

  public resetPassword(data): Observable<any> {
    const requestUrl = this.Endpoints.serverEndpoint + `account/reset-password`;
    return this.httpClient.post<any>(requestUrl, data);
  }

  public changePassword(data): Observable<boolean> {
    const requestUrl =
      this.Endpoints.serverEndpoint + `account/change-password`;
    return this.httpClient.post<boolean>(requestUrl, data);
  }

  public resendConfirmationEmail(email): Observable<boolean> {
    const requestUrl =
      this.Endpoints.serverEndpoint + `account/resend-confirmation`;
    return this.httpClient.post<boolean>(requestUrl, { email });
  }

  public verifyEmailToken(token: string): Observable<any> {
    const requestUrl = this.Endpoints.serverEndpoint + `account/confirm-email`;
    return this.httpClient.get<any>(requestUrl, { params: { token } });
  }
}
