import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { CurrentUserService } from '../factories/current-user.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuardService implements CanLoad {
  constructor(
    private currentUserService: CurrentUserService,
    private router: Router
  ) { }
  canLoad() {
    const currentUser = this.currentUserService.getTempUserProfile();
    const currentUserRole = currentUser ? currentUser.role : false;
    if (
      currentUser &&
      (currentUserRole === 'SuperAdmin' || currentUserRole === 'Administrator')
    ) {
      return true;
    } else if (
      currentUser &&
      !(currentUserRole === 'SuperAdmin' || currentUserRole === 'Administrator')
    ) {
      this.router.navigate(['/dashboard']);
      return false;
    } else {
      this.router.navigate(['login/admin']);
      return false;
    }
  }
}
