import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";

@Component({
  selector: "generic-password-control",
  templateUrl: "./password-control.component.html",
  styleUrls: ["./password-control.component.scss"],
})
export class PasswordControlComponent implements OnInit, AfterViewInit {
  control: string;
  @Input("PasswordPlaceholder") PasswordPlaceholder: string = "Password";
  @Output("EmitPassword") EmitPassword: EventEmitter<string> =
    new EventEmitter();
  @Input("showInputValidators") showInputValidators: boolean;

  toggleText: boolean = true;
  showValidator: boolean;

  state: boolean;
  passwordStrength: HTMLElement;
  lowUpperCase: HTMLElement;
  number: HTMLElement;
  specialChar: HTMLElement;
  eightChar: HTMLElement;

  constructor() {}

  ngAfterViewInit(): void {
    if (this.showInputValidators) {
      this.passwordStrength = document.getElementById("password-strength");
      this.lowUpperCase = document.querySelector(".low-upper-case > mat-icon");
      this.number = document.querySelector(".one-number > mat-icon");
      this.specialChar = document.querySelector(".one-special-char > mat-icon");
      this.eightChar = document.querySelector(".eight-character > mat-icon");
    }
  }

  ngOnInit(): void {
    this.state = false;
  }

  updateFormControl(value: string): void {
    if (this.showInputValidators) {
      this.showValidator = true;
      if (this.checkStrength(value) === 4) {
        this.EmitPassword.emit(value);
      } else {
        this.EmitPassword.emit(null);
      }
    } else {
      this.EmitPassword.emit(value);
    }
  }

  checkStrength(password: string): Number {
    let strength = 0;

    //If password contains both lower and uppercase characters
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
      strength += 1;
      this.lowUpperCase.classList.remove("checked");
      this.lowUpperCase.classList.add("checked");
      this.lowUpperCase.innerHTML = "check";
    } else {
      this.lowUpperCase.classList.add("checked");
      this.lowUpperCase.classList.remove("checked");
      this.lowUpperCase.innerHTML = "warning";
    }
    //If it has numbers and characters
    if (password.match(/([0-9])/)) {
      strength += 1;
      this.number.classList.remove("checked");
      this.number.classList.add("checked");
      this.number.innerHTML = "check";
    } else {
      this.number.classList.add("checked");
      this.number.classList.remove("checked");
      this.number.innerHTML = "warning";
    }
    //If it has one special character
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
      strength += 1;
      this.specialChar.classList.remove("checked");
      this.specialChar.classList.add("checked");
      this.specialChar.innerHTML = "check";
    } else {
      this.specialChar.classList.add("checked");
      this.specialChar.classList.remove("checked");
      this.specialChar.innerHTML = "warning";
    }
    //If password is greater than 7
    if (password.length > 7) {
      strength += 1;
      this.eightChar.classList.remove("checked");
      this.eightChar.classList.add("checked");
      this.eightChar.innerHTML = "check";
    } else {
      this.eightChar.classList.add("checked");
      this.eightChar.classList.remove("checked");
      this.eightChar.innerHTML = "warning";
    }

    // If value is less than 2
    if (strength < 2) {
      this.passwordStrength.classList.remove("progress-bar-warning");
      this.passwordStrength.classList.remove("progress-bar-success");
      this.passwordStrength.classList.add("progress-bar-danger");
      this.passwordStrength.setAttribute("style", "width: 10%");
    } else if (strength == 3) {
      this.passwordStrength.classList.remove("progress-bar-success");
      this.passwordStrength.classList.remove("progress-bar-danger");
      this.passwordStrength.classList.add("progress-bar-warning");
      this.passwordStrength.setAttribute("style", "width: 60%");
    } else if (strength == 4) {
      this.passwordStrength.classList.remove("progress-bar-warning");
      this.passwordStrength.classList.remove("progress-bar-danger");
      this.passwordStrength.classList.add("progress-bar-success");
      this.passwordStrength.setAttribute("style", "width: 100%");
    }

    return strength;
  }
}
