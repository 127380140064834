import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ReceiptComponent } from "../dashboard/receipt/receipt.component";
import { AdminLoginComponent } from "./admin-login/admin-login.component";
import { AdminRegistrationComponent } from "./admin-registration/admin-registration.component";
import { ConfirmAccountComponent } from "./confirm-account/confirm-account.component";
import { EmailConfirmedComponent } from "./email-confirmed/email-confirmed.component";
import { FirmRegistrationComponent } from "./firm-registration/firm-registration.component";
import { JoinComponent } from "./join/join.component";
import { LoginComponent } from "./login/login.component";
import { LogoutComponent } from "./logout/logout.component";
import { RegistrarRegistrationComponent } from "./registrar-registration/registrar-registration.component";
import { RegistrationComponent } from "./registration/registration.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";

const routes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "logout/:email", component: LogoutComponent },
  { path: "case-register", component: RegistrationComponent },
  { path: "confirm-email/:email", component: ConfirmAccountComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "register", component: RegistrarRegistrationComponent },
  { path: "join", component: JoinComponent },
  { path: "receipt", component: ReceiptComponent },
  { path: "email-confirmed", component: EmailConfirmedComponent },

  // Admin authentication
  { path: "login/admin", component: AdminLoginComponent },
  { path: "register/admin", component: AdminRegistrationComponent },
  { path: "firm-reg", component: FirmRegistrationComponent },

  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
